//Dependencies
import { Link } from "react-router-dom";

//Chakra
import {
  Breadcrumb,
  BreadcrumbItem,
  Text,
  chakra
} from "@chakra-ui/react";

//Helpers
import { formatDecodeURI } from "../helpers/utils";


export default function Breadcrumbs({crumbs}) {
  const LinkWChakra = chakra(Link);

  return (
    <Breadcrumb>
      { crumbs.map(({key, breadcrumb}, i) => (
        <BreadcrumbItem key={i} isCurrentPage={i === crumbs.length - 1 ? true : false }>
          { i < crumbs.length - 1 ?
            <LinkWChakra to={key} color={'green.500'} fontWeight={'500'}>{formatDecodeURI(breadcrumb.props.children)}</LinkWChakra>
            :
            <Text>{formatDecodeURI(breadcrumb.props.children)}</Text>
          }
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
}