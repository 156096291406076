//Chakra
import {
  Flex,
  Text,
  Spinner,
  useColorMode
} from '@chakra-ui/react';


export default function Loader() {
  const {colorMode} = useColorMode();

  return (
    <Flex position={'absolute'} top={0} left={0} zIndex={'overlay'} bgColor={colorMode === 'light' ? 'white' : 'black'} direction={'column'} w={'100vw'} h={'100vh'} alignItems={'center'} justifyContent={'center'}>
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='primary'
        size='xl'
      />
      <Text color={'text'} mt={'xs'}>Loading...</Text>
    </Flex>
  )
}


