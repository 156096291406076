// Dependencies
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Chakra
import {
  Heading,
  Flex,
  IconButton,
  chakra,
} from '@chakra-ui/react';
import { ewavesConfig } from '../config/theme-config';

// Providers
import { useChart } from '../providers/chart';
import { useView } from '../providers/view';

// Components
import {
  Chart,
  Fullscreen,
  DownloadBlobButton
} from "../components";

// Hooks
import { useWindowSize } from '../hooks';


export default function ChartHeader() {
  const [width, height] = useWindowSize();
  const {activeGridView} = useView();
  const {activeMarket: market, zoom, chartBlob} = useChart();
  const LinkWChakra = chakra(Link);

  // create filename in this format: Symbol-YYMMDD-Zoom.svg
  const filename = `${market?.json?.Symbol}-${(new Date()).toISOString().slice(0,10).replace(/-/g,"")}-z${zoom}`;

  const buttonStyles = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    p: '1',
    width: '40px',
    height: '40px',
    borderRadius: 'sm',
    '&:hover': {
      bgColor: 'gray.200',
      textDecoration: 'none'
    }
  }

  const chartHeight = useMemo(() => {
    const targetAspectRatio = 16 / 9;
    const currentAspectRatio = width / height;

    if(currentAspectRatio > targetAspectRatio) {
      return height - 10;
    } else {
      return null;
    }
  }, [width, height]);

  return (
    <>
      <Flex className={'chart-header'} justifyContent={'space-between'} alignItems={'center'} minHeight={ewavesConfig.chartHeaderMinHeight+'px'} width={'100%'} mx={'auto'} paddingInlineStart={'xs'}>  
        <Heading size={activeGridView.view === 'thumbnail-small' ? 'sm' : 'md'} display={'block'} width={'65%'} mt={'2px'} fontSize={{base: 'xs', md: 'sm', lg:'md', xl: 'lg'}} lineHeight={'1.1'}>
            <LinkWChakra to={`${market?.json?.Symbol}?z=${zoom}`} color={'primary'} width={'100%'} display={'inline-block'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} overflow={'hidden'}>
              {market?.json?.Title} <FontAwesomeIcon icon="fa-light fa-angle-right" />
            </LinkWChakra>
          </Heading>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Fullscreen
            open={<IconButton colorScheme='transparent' color={'text'} aria-label='Maximize Chart' icon={<FontAwesomeIcon icon="fa-sharp fa-solid fa-maximize" size="lg" />} sx={buttonStyles} />}
          >
            <Chart maxChartHeight={chartHeight} />
          </Fullscreen>
          <DownloadBlobButton blob={chartBlob} filename={filename} />
        </Flex>
      </Flex>
    </>
  )
}