// Dependencies
import {
  useEffect,
  useMemo,
  forwardRef
} from 'react';

// Chakra
import {
  Box,
} from '@chakra-ui/react';

// Providers
import { useChart } from '../providers/chart';
import { useView } from '../providers/view';


const ChartFromBinary = (props) => {
  const { innerRef, chart } = props;
  const { activeGridView, isSingleView, isLoadingChartViews } =  useView();
  const { fullscreen, setWidth, setChartBlob } = useChart();

  // useEffect(() => {
  //   const svgToBlob = (svgElement) => {
  //     const serializer = new XMLSerializer();
  //     const svgString = serializer.serializeToString(svgElement);
  //     return new Blob([svgString], { type: 'image/svg+xml' });
  //   };

  //   if(chart & innerRef?.current) {
  //     const svg = innerRef.current.querySelector('svg');
  //     if(svg) {
  //       console.log(innerRef.current);
  //       svg.querySelector('#background').setAttribute('style', `fill: var(--chakra-colors-chart)`);
  //       if((activeGridView.view === 'full' || isSingleView || fullscreen)) {
          
  //         setWidth(svg.getBoundingClientRect().width);
  //       }
  //       setChartBlob(svgToBlob(svg));
  //     }
  //   }
  // }, [chart, innerRef, setChartBlob, setWidth, fullscreen, isSingleView, activeGridView.view]);

  return (
    <>
      {!isLoadingChartViews &&
        <Box
          display={'flex'} justifyContent={'center'}
          className={'bchart'}
          ref={innerRef}
          dangerouslySetInnerHTML={{__html: chart}}
        />
      }
    </>
  );
}

const ChartFromBinaryWithRef = forwardRef((props, ref) => (
  <ChartFromBinary
    innerRef={ref}
    {...props}
  />
));

export default ChartFromBinaryWithRef;