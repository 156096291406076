//Dependencies
import { useMemo, useEffect } from 'react';

//Chakra
import {
  Box,
  Badge,
  Heading,
  VStack,
  Flex,
  Text,
  Wrap,
  WrapItem
} from '@chakra-ui/react';

//Providers
import { useView } from '../providers/view';

//Helpers
import { groupBy } from '../helpers/utils';

//CHANGE TO USE MARKET TAGS
export default function ChartInfo({numOfResults}) {
  const {activeTags} = useView();
  const groupedTags = useMemo(() => Object.values(groupBy(activeTags, 'region')), [activeTags]);

  return (
    <VStack mb={{base: 4, md: 0}} alignItems={'flex-start'}>
      <Heading size={'xl'} lineHeight={'1'} mb={'xs'}>Viewing Markets</Heading>
      <Text size={'md'}>Total <strong>{numOfResults ? numOfResults : 0}</strong> results found.</Text>
      {activeTags.length && (
        <Box mt={2} textAlign={'left'}>
          <Text mb={2} size={'md'} lineHeight={1}><strong>Filtered By</strong>:</Text>
          <Wrap maxW={'450px'} justify={'flex-start'}>
            {groupedTags.map((group, i) => (
              <WrapItem key={i}>
                <Flex className="group-container" bgColor={'gray.200'} borderRadius={'sm'}>
                <Badge bgColor={'transparent'} fontWeight={'bold'}>{group[0].region}</Badge>
                  {group.map((tag, index) => (
                    <Text as='span' key={tag.asset+index} lineHeight={1.5} display={'flex'} alignItems={'center'}>
                      <Badge className={`tag-group-${tag.region}`} bgColor={'transparent'} color={'blackAlpha.900'} fontWeight={'bold'}>{tag.asset}</Badge>
                      {index < (group.length - 1) ? <Text lineHeight={0} color={'blackAlpha.900'}>+</Text> : ''}
                    </Text>
                  ))}
                </Flex>
              </WrapItem>
            ))}
          </Wrap>
        </Box>
      )}
    </VStack>
  )
}