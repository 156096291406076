import { theme, extendTheme } from '@chakra-ui/react';
import { selectTheme } from './select-theme';


const ewavesTheme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        borderRadius: 'sm',
      },
      sizes: {},
      variants: {
        primary: {
          color: 'text',
          borderWidth: '2px',
          borderColor: 'text',
          '&.active': {
            color: 'whiteAlpha.900',
            bgColor: 'gray.700',
          }
        }
      },
    },
    Select: selectTheme,
  },
  colors: {
    primary: {
      100: theme.colors.green[200],
      200: theme.colors.green[400],
      300: theme.colors.green[700],
    },
    chart: theme.colors.gray[100],
  },
  breakpoints: {
    desktop: theme.breakpoints.xl,
  },
  styles: {
    global: (props) => ({
      'body': {
        overflow: 'hidden',
      },
      '.transitioning *': {
        transition: 'none!important',
      },
      'svg.animating': {
        visibility: 'hidden',
      },
      'input::placeholder': {
        color: props.colorMode === 'dark' ? 'gray.500' : 'gray.400',
      },
      '.charts-container': {
        px: {base: '0', xl: '1vmin'},
        flexDir: 'column',
        w: '100%',
      },
      '.chart-container': {
        px: {base: '0'},
        flexDir: 'column',
        w: '100%',
        '.single-view': {
          px: {base: '0', xl: '1vmin'},
        }
      },
      '.chakra-input, .chakra-input__group': {
        borderRadius: 'md'
      },
      '.chakra-accordion__item': {
        border: 'none',
      },
      '.chakra-modal__body': {
        bgColor: 'chart',
      },
      '.main-backdrop': {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '0',
        bgColor: props.colorMode === 'dark' ? 'blackAlpha.400' : 'whiteAlpha.200',
        backdropFilter: 'auto',
        backdropBlur: '2px',
        backdropBrightness: props.colorMode === 'dark' ? '0.5' : '1.0',
      },
      '.pagination': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: {base: 'space-between', sm: 'center'},
        listStyle: 'none',
      
        '.pagination__page-previous': {
          fontWeight: 'bold',
          mr: {base: 2, desktop: 4},
        },
        '.pagination__page-next': {
          fontWeight: 'bold',
        },
        '.pagination__page': {
          textAlign: 'center',
          fontWeight: 'bold',
          mr: {base: 2, desktop: 4},
          color: 'text',
          '&.selected': {
            color: 'white',
            bgColor: 'primary',
            border: '2px solid',
            borderColor: 'primary',
            borderRadius: 'sm',
          },
          'a': {
            display: 'inline-block',
            height: '1.5em',
            minWidth: '1.5em',
            lineHeight: '1.5em',
            whiteSpace: 'nowrap',
          }
        },
        '.break': {
          textAlign: 'center',
          mr: 4,
        },
        '.disabled': {
          'a': {
            pointerEvents: 'none',
            '.pagination__button': {
              opacity: 0.25,
              pointerEvents: 'none',
            },
          },
        },
      },
    }),
  },
  semanticTokens: {
    space: {
      xs: 2,
      sm: 4,
      md: 6,
      lg: 10,
      xl: 18,
    },
    colors: {
      text: {
        default: 'black',
        _dark: 'white',
      },
      input: {
        default: 'whiteAlpha.800',
        _dark: 'blackAlpha.700',
      },
      primary: {
        default: 'green.500',
        _dark: 'green.400',
      },
      secondary: {
        default: 'green.400',
        _dark: 'green.700',
      },
      tertiary: {
        default: 'gray.200',
        _dark: 'gray.300',
      },
      bgPrimary: {
        default: 'gray.50',
        _dark: 'gray.900',
      },
      bgSecondary: {
        default: 'gray.200',
        _dark: 'gray.700',
      },
      bgTertiary: {
        default: 'gray.400',
        _dark: 'gray.500',
      }
    },
  }
});

const ewavesConfig = {
  chartGridGap: 30,
  chartThumbWidthMax: 700,
  sidebarWidth: 325,
  headerHeightLarge: 50,
  headerHeightSmall: 40,
  chartHeaderMinHeight: 45,
  chartFooterMinHeight: 45,
}

export {ewavesTheme, ewavesConfig};