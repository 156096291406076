// Dependencies
import {
  useEffect,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

// Chakra
import { Box, Skeleton } from '@chakra-ui/react';

// Providers
import { useView } from '../providers/view';
import { ChartProvider, useChart } from '../providers/chart';
import { useWaveFinder } from '../providers/wave-finder';
import { useMarkets } from '../providers/markets';

// Views
import Single from './single';
import Full from './full';
import Thumbnail from './thumbnail';

// Helpers
import { getMarketBySymbol } from '../helpers/utils';



const CurrentView = ({type}) => {
  const {fullscreen} = useView();
  const {setChartInView} = useChart();

  const {ref: lazyRef, inView} = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const getViewType = (type) => {
    switch(type) {
      case 'full':
        return (<Full />);
      case 'thumbnail-small':
        return (<Thumbnail />);
      case 'thumbnail':
        return (<Thumbnail />);
      case 'single':
        return (<Single />);
      default:
        return (<Thumbnail />);
    }
  }

  useEffect(() => {
    setChartInView(inView);
  }, [setChartInView, inView]);

  return (
    <Box
      ref={lazyRef}
      sx={type !== 'single' ? {
        position: 'relative',
        width: '100%',
        height: 'auto',
        backgroundColor: 'chart',
        border: '2px solid',
        borderColor: 'bgSecondary',
        borderRadius: 'sm',
        overflow: 'hidden'
      } : 
      {
        width: '100%',
      }}
    >
      { getViewType(type) }
    </Box>
  )
}

export default function Views() {
  const {activeGridView} = useView();

  return (
    <>
      <Box
        className={'chart-container'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: 'auto',
          width: '100%',
          mx: 'auto',
        }}
      >
        <CurrentView type={activeGridView?.view} />
      </Box>
    </>
  );
}