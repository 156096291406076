//Dependencies
import {
  useState,
  useEffect
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Chakra
import {
  HStack,
  IconButton,
  Spinner,
} from "@chakra-ui/react";

//Providers
import { useView } from "../providers/view";

//Helpers
import { ewavesConfig } from "../config/theme-config";


const ViewButton = ({...props}) => {
  const {viewState} = props;
  const {activeGridView, setActiveGridView} = useView();
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    if(viewState.curView !== props.view) {
      setLoading(true);
      viewState.setCurView(props.view);
    }
  }

  //Ensure default active state on first render
  useEffect(() => {
    if(activeGridView.view === null) {
      if(props.default) {
        setActiveGridView({...activeGridView, typeId: props.id, size: props.size, view: props.view});
      }
    }
  }, []);

  //update active view once loading starts
  useEffect(() => {
    if(loading) {
      setActiveGridView({...activeGridView, typeId: props.id, size: props.size, view: props.view});
    }
  }, [loading, setActiveGridView, activeGridView, props.id, props.size, props.view]);

  //Turn off loading after active view has finished updating
  useEffect(() => {
    setLoading(false);
  }, [activeGridView]);

  return (
    <IconButton
      id={props.id}
      className={`view-selector__button ${activeGridView.typeId === props.id ? 'active' : ''}`}
      aria-label={props.label}
      title={props.label}
      size={'sm'}
      colorScheme={'gray'}
      variant={'primary'}
      icon={loading ? <Spinner size={'sm'} speed={'0.6s'} /> : props.icon}
      onClick={handleClick} />
  )
}

export default function ViewSelector() {
  const {activeGridView} = useView();
  const [curView, setCurView] = useState(activeGridView?.view);

  return (
    <HStack>
      <ViewButton
        id="smallGrid"
        view={'thumbnail-small'}
        size={{
          base: `100%`,
          sm: `repeat(auto-fill, minmax(calc(200px), 1fr))`,
          lg: `repeat(auto-fill, minmax(calc(250px), 1fr))`,
          xl: `repeat(auto-fill, minmax(calc(30% - ${ewavesConfig.chartGridGap}px), 1fr))`
        }}
        label={'Small Grid View'}
        icon={<FontAwesomeIcon icon="fa-sharp fa-solid fa-grid" size="lg" />}
        viewState={{curView, setCurView}}
      />
      <ViewButton
        id="bigGrid"
        default={true}
        view={'thumbnail'}
        size={{
          base: `100%`,
          sm: `repeat(auto-fill, minmax(calc(50% - ${ewavesConfig.chartGridGap}px), 1fr))`
        }}
        label={'Big Grid View'}
        icon={<FontAwesomeIcon icon="fa-sharp fa-solid fa-grid-2" size="lg" />}
        viewState={{curView, setCurView}}
      />
      <ViewButton
        id="fullView"
        view={'full'}
        size={'100%'}
        label={'Full View'}
        icon={<FontAwesomeIcon icon="fa-sharp fa-solid fa-square" size="lg" />}
        viewState={{curView, setCurView}}
      />
    </HStack>
  )
}