// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator, getDoc, setDoc, doc } from "firebase/firestore";
import { getStorage, connectStorageEmulator, ref, getBlob, getMetadata } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";

import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";


// LIVE web app's Firebase configuration
// let firebaseConfig = {
//   apiKey: "AIzaSyCOPUKlF1Yl6QrzHKA0qtwgTBUIgUtBHEw",
//   authDomain: "ewaves-8a6af.firebaseapp.com",
//   projectId: "ewaves-8a6af",
//   storageBucket: "ewaves-8a6af.appspot.com",
//   messagingSenderId: "604618742390",
//   appId: "1:604618742390:web:5ca868f663ee4c40dc5d57"
// };

// DEV web app's Firebase configuration
// let firebaseConfig = {
//   apiKey: "AIzaSyCSC0kFIvRMpqTzDSdK75ZXEu34RhGyWJA",
//   authDomain: "ewaves-live-dev.firebaseapp.com",
//   projectId: "ewaves-live-dev",
//   storageBucket: "ewaves-live-dev.appspot.com",
//   messagingSenderId: "265142576618",
//   appId: "1:265142576618:web:149b47e040bdfe0f267e93"
// };

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

//Initialize Firebase
const app = initializeApp(firebaseConfig);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
if(process.env.REACT_APP_FIREBASE_PROJECT_ID === "ewaves-live-dev") {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APP_CHECK_KEY; /* eslint-disable-line no-restricted-globals */
} 
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lc50wklAAAAAP39EIqFhw1C1j_6h7GXneDfqHYi'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

//Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

//Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

//Initialize Cloud storage and get a reference to the service
const storage = getStorage(app, `gs://${firebaseConfig.storageBucket}`);

//Initialize cloud functions
const functions = getFunctions(app);
const resetEmail = httpsCallable(functions, 'resetEmail');

if(process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_EMULATORS === true) {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectStorageEmulator(storage, "localhost", 9199);
}

//Set data to supplied user ID. Either adds new user or merges passed data into existing user.
const setFBUser = async (userID, data) => {
  try {
    //sends data changes to firestore
    console.log('Attempting with data...', data);
    return await setDoc(doc(db, 'users', userID), data, { merge: true});
  } catch (e) {
    const message = `Attempted setUser, Firestore returned ${e.code}.`
    throw new Error(message)
  }
}

//Gets the data of user associated with user ID
const getFBUser = async (userID) => {
  try {
    const docSnapshot = await getDoc(doc(db, 'users', userID));
    return docSnapshot.data();
  } catch (e) {
    const message = `Attempted getUser, Firestore returned ${e.code}.`
    throw new Error(message)
  }
}

//Get cloud storage file as a blob using file and path name
const getGCStorageBlobByName = async (path, name) => {
  try {
    const fileRef = ref(storage, `${path}/${name}`);
    const blob = await getBlob(fileRef);
    return blob;
  } catch(error) {
    console.error('Error getting blob:', error);
  }
}

//Get cloud storage file metadata
const getGCStorageMetadataByName = async (path, name) => {
  try {
    const fileRef = ref(storage, `${path}/${name}`);
    const metadata = await getMetadata(fileRef);
    return metadata;
  } catch(error) {
    console.error('Error getting metadata:', error);
  }
}

export { auth, db, functions, resetEmail, setFBUser, getFBUser, getGCStorageBlobByName, getGCStorageMetadataByName };