//Chakra
import {
  IconButton,
} from '@chakra-ui/react';


export default function ZoomButton({label, className, active, icon, ...props}) {
  const buttonStyles = {
    m: 0,
    mr: 'md',
    '&:last-of-type': {
      mr: 0,
    },
    '&.disabled': {
      opacity: 0.25,
      pointerEvents: 'none',
    }
  }

  return (
    <IconButton
      {...props}
      aria-label={label}
      title={label}
      className={`${className}${!active ? ' disabled' : ''}`}
      size={'sm'}
      colorScheme={'green'}
      variant={'solid'}
      icon={icon}
      sx={buttonStyles}
    />
  )
}