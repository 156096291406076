import { selectAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const primary = definePartsStyle({
  field: {
    background: 'transparent',
    borderWidth: '2px',
    borderColor: 'blackAlpha.900',
    borderRadius: 'sm',
    fontWeight: 'medium',
    _hover: {
      background: 'whiteAlpha.800',
      cursor: 'pointer',
    },
    _dark: {
      borderColor: 'whiteAlpha.900',
    }
   }
});

const lightPrimary = definePartsStyle({
  field: {
    background: 'transparent',
    borderWidth: '2px',
    borderColor: 'gray.300',
    borderRadius: 'sm',
    color: 'gray.500',
    fontWeight: 'medium',
    _hover: {
      background: 'whiteAlpha.800',
      cursor: 'pointer',
    },
    _dark: {
      borderColor: 'whiteAlpha.900',
    }
   }
});

const sizes = {
  standard: definePartsStyle({
    field: defineStyle({
      '--input-padding': '2rem',
      fontSize: 'md',
      pl: '4',
      h: '8',
    }),
    icon: defineStyle({
      fontSize: 'xl',
      h: '8',
    }),
  }),
  small: definePartsStyle({
    field: defineStyle({
      '--input-padding': '1rem',
      fontSize: 'sm',
      pl: '2',
      h: '7',
    }),
    icon: defineStyle({
      fontSize: 'lg',
      h: '7',
    }),
  })
}

export const selectTheme = defineMultiStyleConfig({ sizes, variants: { primary, lightPrimary } })