//Dependencies
import { useRef, useState, useEffect, useContext, useMemo, createContext } from 'react';

//Providers
import { useMarkets } from './../markets';
import { useUser } from './../user';
import { useView } from './../view';


const WaveFinderContext = createContext();

// Finds waves from the Wave Finder and sets those into the Wave Finder's "cache"
const find_waves = async (wf, waveIds, sortBy, sortAsc, query) => {
  return await wf.find_waves(waveIds, {"sortBy": sortBy, "sortAscending": sortAsc, "query": query});
}

// Gets the waves that were stored by a previous findWaves call
const get_waves = async (wf, pageNumber, itemsPerPage) => {
  return await wf.get_waves(pageNumber, itemsPerPage);
}

function useWaveFinder() {
  const context = useContext(WaveFinderContext);
  if (!context) {
    throw new Error(`useWaveFinder must be used within a WaveFinderProvider`);
  }

  return context;
}

function WaveFinderProvider(props) {
  const {token} = useMarkets(); // Unique token used to retrieve external files
  const {user} = useUser(); // Necessary to determine correct package module to load. Helps "hot swap" packages without reload
  const {itemsPerPage} = useView(); // Necessary to determine # of results from wave finder

  // Used internally by provider
  const [wf, setWf] = useState(null); // Gets main functionality from helper script and worker

 const wfActive = useMemo(() => {
  return user?.queryToggle ? user.queryToggle : false; // Determine if WF is actively finding waves
 }, [user?.queryToggle]);

  // Available through useWaveFinder to every component under the provider
  const [queryInfo, setQueryInfo] = useState(null); // Gets default information to generate UI elements for wavefinder
  const [maxWaves, setMaxWaves] = useState({qty: null}); // Returned from finding # waves from find_waves. Is type object so that equal quantities trigger update
  const [waves, setWaves] = useState(null); // Array of wave objects consisting of ids and zooms
  const [waveIds, setWaveIds] = useState([]); // Ids of waves to retrieve. Empty gets everything
  const [sortBy, setSortBy] = useState('elliotticity'); // Set how waves are sorted via find_waves. Values come for queryInfo
  const [sortAsc, setSortAsc] = useState(false); // Boolean for true (ascending) or false (descending)
  const [wfQuery, setWfQuery] = useState({}); // Consists of options and actual "query" object of waves

  const [pageNumber, setPageNumber] = useState(0); // Current page number of waves to return
  const [builderStateReady, setBuilderStateReady] =  useState(false);

  const [isLoadingWaves, setIsLoadingWaves] = useState(false);

  const canGetWaves = useRef(false);

  // Populate wavefinder module from script (External | Async)
  useEffect(() => {
    if(user?.package) {
      // URL of the JavaScript file you want to load
      const moduleUrl = `${process.env.REACT_APP_ASSETS_PATH}/${user?.package}/wavefinder.js`;

      // Use dynamic import to load the module
      import(/* webpackIgnore: true */ moduleUrl)
        .then((module) => {
          setWf(module);
        })
        .catch((error) => {
          console.error('Failed to load module:', error);
        });
    }
  }, [user?.package]);

  //Get query info initializes the Wave Finder so we can then make calls
  useEffect(() => {
    const initializeWaveFinder = async () => {
      try {
        const template = await wf.get_query_info(`${process.env.REACT_APP_ASSETS_PATH}/${user?.package}/`, token);
        setQueryInfo(template);
      } catch (err) {
        console.error(`Error getting query template: ${err}`);
      } 
    }

    if(wf && token && user?.package) {
      console.log('Getting query information...');
      initializeWaveFinder();
    }
  }, [wf, user?.package, token]);

  // If wave finder and query info have already been retrieved, executes query and returns num of waves found then gets first page of wave results
  // ( Async )
  useEffect(() => {
    if(wf && queryInfo && builderStateReady) {
      setIsLoadingWaves(true);
      find_waves(wf, waveIds, sortBy, sortAsc, wfQuery)
        .then(num => {
          console.log('Found waves', num);
          setMaxWaves({"qty": num});
          canGetWaves.current = true;
        })
        .catch(err => {
          console.error(`Error finding waves: ${err}`);
        });
    }
  }, [queryInfo, wf, waveIds, sortBy, sortAsc, wfQuery, builderStateReady, setIsLoadingWaves]);

  // If we've found waves (findWaves) then get those specific waves based on desired number of items
  // ( Async )
  useEffect(() => {
    if(canGetWaves.current && wf && (maxWaves?.qty || maxWaves.qty === 0)) {
      get_waves(wf, pageNumber, itemsPerPage)
      .then(waves => {
        console.log('Then got waves', waves);
        setWaves(waves);
        setIsLoadingWaves(false);
      })
      .catch(err => {
        console.error(`Error getting waves: ${err}`);
      });
    }
  }, [wf, maxWaves, pageNumber, itemsPerPage]);

  const value = useMemo(() => ({
    wf,
    maxWaves, setMaxWaves,
    waves, setWaves,
    waveIds, setWaveIds,
    sortBy, setSortBy,
    sortAsc, setSortAsc,
    wfQuery, setWfQuery,
    pageNumber, setPageNumber,
    isLoadingWaves, setIsLoadingWaves,
    builderStateReady, setBuilderStateReady,
    queryInfo,
    wfActive,
  }), [wf, waveIds, sortBy, sortAsc, wfQuery, wfActive, pageNumber, waves, maxWaves, isLoadingWaves, queryInfo, builderStateReady]);

  return (
    <WaveFinderContext.Provider value={value} {...props} />
  )
}

export {WaveFinderProvider, useWaveFinder, find_waves, get_waves};