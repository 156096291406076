//Dependencies
import { useState, useMemo, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash.debounce';

//Chakra
import {
  Button,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Heading,
  List,
  ListItem,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  useDisclosure,
  PopoverAnchor,
  Text,
  Divider
} from '@chakra-ui/react';

//Providers
import { useMarkets } from '../providers/markets';


export default function Search(props) {
  const {markets, marketIds} = useMarkets(); // get markets from global context
  const {isOpen, onOpen, onClose} = useDisclosure(); // Handles opening closing results popup
  const [searchQuery, setSearchQuery] = useState(null); // Track what's being searched for
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [inputIcon, setInputIcon] =  useState(undefined);
  const resultsPerPage = 100; // Number of results per page
  const [displayedResults, setDisplayedResults] = useState([]); // State to hold displayed results

  const inputRef = useRef();

  const searchHeight = props.h ? props.h : 8; // Determines height of search input field
  const searchWidth = props.w ? props.w : '325px'; // Determines width of input field and search results underneath

  // Filtered markets will take the market indices passed from the active regions and slice (and combine via flat) those from the full markets list
  const filteredMarkets = useMemo(() => {
    if(marketIds && marketIds.length) {
      const searchMarketIndices = marketIds.map(([start, end]) => markets.slice(start, (end + 1)));
      return searchMarketIndices.flat();
    }
  }, [marketIds, markets]);

  const handleClear = () => {
    inputRef.current.value = null;
    setSearchQuery(null);
  }
  const ClearButton = () => {
    return (
      <IconButton bg={'transparent'} aria-label='Clear search' color='primary' size='xs' variant='solid' isRound icon={<FontAwesomeIcon className="icon" icon="fa-regular fa-xmark-large" />} onClick={handleClear} />
    )
  }

  const handleInputChange = (event) => {
    const value = event.target.value;

    if(value) {
      setSearchQuery(value);
    } else {
      setSearchQuery(null);
      setDisplayedResults([]);
    }

    setCurrentPage(1);
  };
  const debouncedHandleInputChange = debounce(handleInputChange, 350, { 'maxWait': 1500 });

  const searchMarkets = useMemo(() => {
    // Used filtered markets unless it's empty
    let sMarkets = filteredMarkets?.length ? filteredMarkets : markets;
    return (keys, searchQuery) => {
      // Convert search query to lowercase for case-insensitive comparison
      const lowercaseQuery = searchQuery.toLowerCase();

      // Filter markets based on search query and keys
      const filteredResults = sMarkets.filter(market => {
        // Loop through each key in the keys array
        for (let key of keys) {
            // Check if the key exists in the market object and if its value contains the search query
            if (market[key] && market[key].toString().toLowerCase().includes(lowercaseQuery)) {
                return true; // Return true if there's a match
            }
        }
        return false; // Return false if no match is found in any key
      });

      // Sort the filtered results alphabetically by name
      const sortedResults = filteredResults.sort((a, b) => {
          const symbolA = a.Symbol.toLowerCase();
          const symbolB = b.Symbol.toLowerCase();
          if (symbolA < symbolB) return -1;
          if (symbolA > symbolB) return 1;
          return 0;
      });

      return sortedResults;
    };
  }, [markets, filteredMarkets]); // Memoize based on markets array

  // Load More Results
  const filteredResults = useMemo(() => {
    const results = searchQuery ? searchMarkets(['Symbol', 'Keywords', 'Title'], searchQuery) : [];
    return results;
  }, [searchMarkets, searchQuery]); // return empty array if there's no search query

  const handleLoadMoreResults = () => {
    const nextResults = filteredResults.slice(
      currentPage * resultsPerPage,
      (currentPage + 1) * resultsPerPage
    );

    setDisplayedResults(prevResults => [...prevResults, ...nextResults]);
    setCurrentPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    return () => {
      debouncedHandleInputChange.cancel();
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(searchQuery) {
      onOpen();
      setInputIcon(<ClearButton />);
    } else {
      onClose();
      setInputIcon(undefined);
    }
    setDisplayedResults([]);
  }, [onOpen, onClose, searchQuery]);

  useEffect(() => {
    const loadResults = () => {
      const indexOfLastResult = currentPage * resultsPerPage;
      const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  
      const currentResults = filteredResults.slice(
        indexOfFirstResult,
        indexOfLastResult
      );
      console.log(currentResults);
      setDisplayedResults(currentResults);
    }

    // If we have filtered results display them, otherwise results are empty.
    // Ensures results list is always updated when changing regions
    if(filteredResults.length) {
      loadResults();
    } else {
      setDisplayedResults([]);
    }
  }, [currentPage, filteredResults]);

  return (
    <Popover
      autoFocus={false}
      isOpen={isOpen}
      placement='bottom'
      isLazy
    >
      <PopoverAnchor>
        <InputGroup {...props}>
          <InputLeftElement
            h={searchHeight}
            pointerEvents='none'
            children={<FontAwesomeIcon className="icon" icon="fa-regular fa-magnifying-glass" />}
          />
          <Input ref={inputRef} h={searchHeight} type='text' placeholder='Search Markets...' color={'text'} bgColor={'field'} onChange={(event) => { if(!event.target.value) { onClose();} debouncedHandleInputChange(event);}} />
          <InputRightElement
            h={searchHeight}
            children={inputIcon}
          />
        </InputGroup>
      </PopoverAnchor>
      <PopoverContent w={searchWidth}>
        <PopoverHeader fontSize={'md'} textAlign={'left'}>
          <Heading size={'sm'}>Search Results:</Heading>
        </PopoverHeader>
        <PopoverBody maxH={'40vmin'} overflowY='auto' textAlign={'center'}>
          <List className='nav-list' fontSize={'md'}>
            { searchQuery && displayedResults.length &&
                displayedResults.map((result, index) => (
                  <ListItem key={index} textAlign='left'>
                    <Link to={`/charts/${result.Symbol}`} onClick={handleClear}>
                      <Text color={'primary'}><strong>{result.Symbol}</strong></Text>
                    </Link>
                    <Text fontSize={'xs'} color={'gray.800'}>{result.Title}</Text>
                    {displayedResults.length !== (index + 1) && <Divider my={2} />}
                  </ListItem>
                ))
            }
            { searchQuery && !displayedResults.length &&
              <ListItem textAlign='left'>No results found.</ListItem>
            }
          </List>
          {filteredResults.length > displayedResults.length && (
            <>
              <Divider my={2} />
              <Button size={'xs'} marginBlock={'3'} colorScheme={'green'} onClick={handleLoadMoreResults}>Load More Results</Button>
            </>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}