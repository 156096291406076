//Dependencies
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

//Chakra
import {
  Stack,
  Box,
  Button,
  Heading,
  Flex,
  Link,
  Text,
  useColorMode
} from '@chakra-ui/react';

//Components
import { Search } from '../components';

//Displays if user navigates to invalid page
const NoMarket = () => {
  const {colorMode} = useColorMode();
  const navigate = useNavigate();

  return (
    <Flex justifyContent={'center'} alignItems={'center'} height={'100vh'}>
      <Box p={{base: '5', sm: '10', lg: '20'}} bgColor={colorMode === 'light' ? 'gray.300' : 'gray.600'} width={{base: '100%', sm: '500px', md: '600px'}}>
        <Box width={'100%'} textAlign={'left'}>
          <Button size='sm' color={'primary'} variant={'link'} alignItems={'baseline'} aria-label='Go back' bgColor={'transparent'} leftIcon={<FontAwesomeIcon icon="fa-solid fa-angle-left" />} onClick={() => {navigate(-1)}}>BACK</Button>
        </Box>
        <Stack height={'100%'} className='no-match' alignItems={'center'} spacing={5}>
          <Heading size={'xl'} width={'100%'}>Market wasn't found!</Heading>
          <Text width={'100%'}>Please recheck that the url / symbol you entered didn't have an error. You can also contact support at <Link color={'primary'} href="mailto:support@ewaves.com" isExternal>support@ewaves.com</Link></Text>
          <Box w={'100%'}>
            <Text mb={'xs'}>Try searching for a market below:</Text>
            <Search h={10}  alignSelf='center' />
          </Box>
        </Stack>
      </Box>
    </Flex>
  );
}

export default NoMarket;