//Dependencies
import { useEffect } from 'react';
import {
  withFormik
} from 'formik';
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

//Chakra
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Text,
  Heading,
  Flex,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useColorMode,
  useDisclosure
} from "@chakra-ui/react";

//Providers
import { useAuthentication } from '../providers/authentication';

//Helpers
import { auth } from '../helpers/firebase';


const VerificationMessage = ({status, setSubmitting}) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleReturnToLogin = () => {
    onClose();
    navigate('/login', {replace: true});
  }

  useEffect(() => {
    if(status.message) {
      onOpen();
      setSubmitting(false);
    };
  }, [status]);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {status.heading}
          </AlertDialogHeader>

          <AlertDialogBody bgColor={'white'}>
            {status.message}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button colorScheme={status.hasError ? 'red' : 'green'} onClick={handleReturnToLogin} ml={3}>
              {status.action}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

const Reset = props => {
  const {setIsLoading} = useAuthentication();
  const {
    values,
    touched,
    status,
    errors,
    setSubmitting,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;
  const navigate = useNavigate();
  const {colorMode} =  useColorMode();

  values.navigate = navigate;
  values.setIsLoading = setIsLoading;

  return (
    <Flex justifyContent={'center'} alignItems={'center'} height={'100vh'}>
      {status?.message && <VerificationMessage status={status} setSubmitting={setSubmitting} />}
      <Box p={{base: '5', sm: '10', lg: '20'}} bgColor={colorMode === 'light' ? 'gray.300' : 'gray.600'} width={{base: '100%', sm: '500px', md: '600px'}}>
        <Box mb={'md'}>
          <Heading size={'md'} mb='xs'>Request Password Reset</Heading>
          <Text>Send a password reset request to the provided email address.</Text>
          <Text mt={'sm'} fontSize={'sm'} fontWeight={700}>
            (Important: Only non-MyEWI users should use this password reset form. MyEWI users should <a href="https://profile.elliottwave.com/password-reset?action=request" target="_blank" rel="noreferrer"><u>reset their password here.</u></a>)
          </Text>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box mb={'md'}>
            <FormControl width={'400px'} mb={'xs'} isInvalid={errors.email && touched.email}>
              <FormLabel htmlFor='email'>Email</FormLabel>
              <Input
                placeholder=''
                id='email'
                bgColor={'white'}
                pr='4.5rem'
                type={'text'}
                name='email'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {errors.email && touched.email && <FormErrorMessage id="feedback">{errors.email}</FormErrorMessage>}
            </FormControl>
          </Box>
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              width={'min-content'}
              mt={4}
              colorScheme='green'
              type='submit'
              isLoading={isSubmitting}
              loadingText='Submitting'
              onClick={() => console.log(`Submitted the following: ${values.email}`)}
            >
              Next
            </Button>
            <Button
                width={'min-content'}
                mt={4}
                colorScheme='green'
                variant='link'
                type='button'
                onClick={() => navigate('/login')}
              >
                Back to Login
              </Button>
          </Flex>
          <Text mt={'sm'} fontSize={'xs'}>(Please Note: If you have trouble receiving the reset email, check your spam folder. It will be from noreply@live.ewaves.com.)</Text>
        </form>
      </Box>
    </Flex>
  )
}

const ResetWithFormik = withFormik({
  mapPropsToValues: () => ({ email: '' }),

  handleSubmit: async (values, {setStatus}) => {
    values.setIsLoading(true);
    await sendPasswordResetEmail(auth, values.email)
      .then(() => { 
        setStatus({hasError: false, message: 'Password reset request sent successfully. Check your inbox for instructions.', heading: 'Password Reset Requested', action: 'Return to Login'});
      })
      .catch((e) => {
        setStatus({hasError: true, message: 'Password reset failed. Please try again or contact support.', heading: 'Password Reset Error', action: 'Try Again'});
      });
  },

  displayName: 'ResetForm',
})(Reset);

export default ResetWithFormik;