import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

//https://stackoverflow.com/a/63010184
export default function useWindowsize() {
  const [size, setSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      console.log('Debounced resize');
      setSize([window.innerWidth, window.innerHeight]);
    }, 100); // 100ms
    window.addEventListener('resize', debouncedResizeHandler);

    return () => window.removeEventListener('resize', debouncedResizeHandler);
  }, []); // Note this empty array. this effect should run only on mount and unmount
  return size;
}