// Dependencies
import { useLayoutEffect, useState } from 'react';

// Chakra
import {
  Skeleton,
} from '@chakra-ui/react';
import { ewavesConfig } from '../config/theme-config';

// Components
import {
  Chart,
  ChartHeader,
  ChartFooter
 } from "../components";

// Providers
import { useView } from '../providers/view';
import { useChart } from '../providers/chart';

// Hooks
import { useDimensions } from '../hooks';


export default function Thumbnail() {
  const {isLoadingChartViews, activeGridView} = useView();
  const {chartInView} = useChart();
  const [totalHeight, setTotalHeight] = useState(0);
  const [sRef, size] = useDimensions();

  const aspectRatio =  16 / 9;

  useLayoutEffect(() => {
    const chartHeight = (size.width / aspectRatio);

    if(chartHeight > 0) {
      setTotalHeight((size.width / aspectRatio) + ewavesConfig.chartFooterMinHeight + ewavesConfig.chartHeaderMinHeight);
    }
  }, [size, aspectRatio]);

  return (
    <>
      <Skeleton ref={sRef} isLoaded={!isLoadingChartViews}
        sx={{
          height: totalHeight + "px",
        }}
      >
          {chartInView &&
            <>
              <ChartHeader />
              <Chart />
              <ChartFooter />
            </>
          }
      </Skeleton>
    </>
  );
}