//Dependencies
import {
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef
} from 'react';
import { useHoverIntent } from 'react-use-hoverintent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEqual from 'lodash.isequal';

//Chakra
import {
  Box,
  HStack,
} from '@chakra-ui/react';

//Components
import {
  ZoomButton,
} from '../components';

//Providers
import { useChart } from '../providers/chart';
import { useWaveFinder } from '../providers/wave-finder';
import { useView } from '../providers/view';

//Hooks
import { useBinaryChart } from '../hooks';


export default function Chart(props) {
  const {maxChartHeight} = props;
  const {activeGridView, setIsLoadingChartViews, fullscreen} = useView();
  const {wfQuery, wfActive} = useWaveFinder();
  const {activeMarket: market, zoom, setZoom, bchart, setChartBlob, isSingleView} = useChart();

  const {chartHTML} = useBinaryChart();

  const [isHovering, intentRef] = useHoverIntent();
  const [controls, setControls] = useState(false);
  const zoomOutTest = useMemo(() => zoom > 0, [zoom]);
  const zoomInTest = useMemo(() => zoom < (bchart?.zooms.length - 1), [zoom, bchart]);
  const zoomOut = () => setZoom(z => z - 1);
  const zoomIn = () => setZoom(z => z + 1);
  
  const chartRef = useRef(null);

  // Clear all waves of visibility
  const clearWaves = () => {
    const refWaves = chartRef?.current?.querySelectorAll('#waves > g');
    if(refWaves) {
      refWaves.forEach(wave => wave.style.visibility = null);
    }
  }

  // Handles showing and hiding controls for zoom UI components. Includes a hover intent delay.
  useEffect(() => {
    if(isHovering) {
      setControls(true);
    } else {
      setControls(false);
    }
  }, [isHovering]);

  useLayoutEffect(() => {
    if(chartHTML) {
      const blob = new Blob([chartHTML], { type: 'image/svg+xml' });
      setChartBlob(blob);
    }
  }, [chartHTML, setChartBlob]);

  // Modify values in the SVG as needed (change colors, etc...) via String.Replace BEFORE we add and render the HTML
  const chartHTMLModified = useMemo(() => {
    if(chartHTML) {
      let mChartHTML = chartHTML;
      
      mChartHTML = mChartHTML.replace('<svg', `<svg ${maxChartHeight ? "height="+maxChartHeight : ''} preserveAspectRatio="xMidYMid meet" style="${!isSingleView && !fullscreen && activeGridView?.view !== 'full' ? 'height: 100%' : ''}"`);
      mChartHTML = mChartHTML.replace(`fill='#F3F3F3'`, `fill='var(--chakra-colors-chart)'`);
      return mChartHTML;
    }

  }, [chartHTML, maxChartHeight, fullscreen, isSingleView, activeGridView?.view]);

  // On load we control the visibility of waves based on the their degree shown plus save downloadable blob version
  useEffect(() => {
    if(chartHTMLModified !== null) {
      // If we have active market data and if wave finder is active,
      // then identify active wave and display it. Otherwise, clear waves visibility
      if(market && market.waves && wfActive) {
        const activeWave = market?.waves[0]?.degree;

        if(!fullscreen && (activeWave || activeWave === 0)) {
          const waveId = '#wave'+activeWave;

          if(chartRef.current) {
            const waveEl = chartRef.current.querySelector(waveId);
            
            if(waveEl) { 
              waveEl.style.visibility = 'visible';
            } else {
              console.error(`Current wave degree ${activeWave} was not found in chart.`);
            }
          }
        } else {
          if(isEqual(wfQuery, {})) {
            clearWaves();
          }
        }
      }
      setIsLoadingChartViews(false);

      if(!wfActive) {
        clearWaves();
      }
    }
  }, [wfActive, chartRef, chartHTMLModified, market, wfQuery, isSingleView, fullscreen, activeGridView?.view, setIsLoadingChartViews]);

  return (
    <Box
      ref={intentRef}
      className={'chart-wrapper'}
      sx={{
        position: 'relative',
        aspectRatio: maxChartHeight ? 'auto' : '16/9',
        width: '100%',
      }}
    >
      <HStack className={'zoom-controls'} display={controls ? 'flex' : 'none'}
        position={'absolute'}
        left={'50%'}
        bottom={'0'}
        transform={'translateX(-50%)'}
        zIndex={'100'}
      >
        <ZoomButton
          className='zoom-controls__button zoom-out'
          label={`Zoom Out`}
          icon={<FontAwesomeIcon icon="fa-sharp fa-solid fa-minus" />}
          active={zoomOutTest}
          onClick={zoomOut}
        />
        <ZoomButton
          className='zoom-controls__button zoom-in'
          label={'Zoom In'}
          icon={<FontAwesomeIcon icon="fa-sharp fa-solid fa-plus" />}
          active={zoomInTest}
          onClick={zoomIn}
        />
      </HStack>
      {chartHTMLModified &&
        <Box
          ref={chartRef}
          display={'flex'} justifyContent={'center'}
          className={'bchart'}
          dangerouslySetInnerHTML={{__html: chartHTMLModified}}
        />
      }  
    </Box>
  );
}
