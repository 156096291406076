//Dependencies
import {
  useEffect,
  useState,
  useMemo
} from "react";
import debounce from "lodash.debounce";

//Chakra
import {
  Heading,
  VStack,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
} from "@chakra-ui/react";

//Providers
import { useBuilder } from "../../providers/builder";

//Helpers
import { toTitleCase } from "../../helpers/utils";


//Range Input represents 2nd type of attribute and has a min and max range and displays the type of units being passed. Can potentially have an infinite min or max range.
const RangeInput = ({contextId, id, name, fields, values}) => {
  const {actions} = useBuilder();

  //Format fields to find only options for this name
  const range = useMemo(() => [...fields].filter(field => field.name === name).pop(), [name, fields]);

  //State for min and max values (Values are null for new attributes.)
  const [min, setMin] = useState(values && values.min !== null && Number.isInteger(values.min) ? values.min : range.min);
  const [max, setMax] = useState(values && values.max !== null && Number.isInteger(values.max) ? values.max : range.max);

  //Debounced inputs
  const handleChangeMin = (valueAsNumber) => {
    setMin(valueAsNumber);
    actions.updateAttribute(contextId, id, null, {min: parseFloat(valueAsNumber), max: parseFloat(max)});
  };
  const handleChangeMax = (valueAsNumber) => {
    setMax(valueAsNumber);
    actions.updateAttribute(contextId, id, null, {min: parseFloat(min), max: parseFloat(valueAsNumber)});
  };
  const debouncedChangeMin = debounce(handleChangeMin, 500);
  const debouncedChangeMax = debounce(handleChangeMax, 500);

  return (
    <VStack alignItems={'flex-start'} spacing={1}>
      <Heading size={'xs'} color={'gray.600'} lineHeight={'inherit'}>{toTitleCase(name)}:</Heading>
      <HStack>
        <NumberInput size='xs' w={'45%'} defaultValue={min} min={range.min} max={range.max} onChange={debouncedChangeMin}>
          <NumberInputField textAlign={'center'} background={'white'}/>
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Text w={'10%'} textAlign={'center'}>to</Text>
        <NumberInput size='xs' w={'45%'} defaultValue={max} min={range.min} max={range.max} onChange={debouncedChangeMax}>
          <NumberInputField textAlign={'center'} background={'white'} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </HStack>
      <Text fontSize={'xs'} fontWeight={'700'} color={'gray.600'}>in {range.units}</Text>
    </VStack>
  )
}

export default RangeInput;