//Dependencies
import { useState, useEffect } from 'react';
import { withFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut
} from "firebase/auth";

//Chakra
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Text,
  Heading,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useColorMode,
  useDisclosure
} from "@chakra-ui/react";

//Helpers
import {
  auth,
  setFBUser
} from '../helpers/firebase';


const VerificationMessage = ({status, setSubmitting}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleReturnToLogin = () => {
    onClose();
    signOut(auth);
  }

  useEffect(() => {
    if(status.message) {
      onOpen();
      setSubmitting(false);
    };
  }, [status]);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {status.heading}
          </AlertDialogHeader>

          <AlertDialogBody bgColor={'white'}>
            {status.message}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button colorScheme={status.hasError ? 'red' : 'green'} onClick={handleReturnToLogin} ml={3}>
              {status.action}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

const Signup = props => {
  const {
    values,
    touched,
    errors,
    status,
    isSubmitting,
    setSubmitting,
    handleChange,
    handleSubmit,
    handleBlur,
  } = props;
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const navigate = useNavigate();
  const {colorMode} =  useColorMode();

  //values added that aren't form controls
  values.setFBUser = setFBUser;

  return (
    <Flex justifyContent={'center'} alignItems={'center'} height={'100vh'}>
      {status?.message && <VerificationMessage status={status} setSubmitting={setSubmitting} />}
      <Box p={{base: '5', sm: '10', lg: '20'}} bgColor={colorMode === 'light' ? 'gray.300' : 'gray.600'} width={{base: '100%', sm: '500px', md: '600px'}}>
        <Box mb={'md'}>
          <Heading size={'md'} mb='xs'>New User Account Setup</Heading>
          <Text>
            Please fill out the form below to create you user account for access to EWAVES Live.
          </Text>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box mb={'md'}>
            <FormControl width={'100%'} mb={'xs'} isInvalid={errors.fname && touched.fname}>
              <FormLabel htmlFor='fname'>First Name</FormLabel>
              <Input
                placeholder=''
                id='fname'
                bgColor={'white'}
                pr='4.5rem'
                type={'text'}
                name='fname'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.fname}
              />
              {errors.fname && touched.fname && <FormErrorMessage id="feedback">{errors.fname}</FormErrorMessage>}
            </FormControl>
            <FormControl width={'100%'} mb={'xs'} isInvalid={errors.lname && touched.lname}>
              <FormLabel htmlFor='lname'>Last Name</FormLabel>
              <Input
                placeholder=''
                id='lname'
                bgColor={'white'}
                pr='4.5rem'
                type={'text'}
                name='lname'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lname}
              />
              {errors.lname && touched.lname && <FormErrorMessage id="feedback">{errors.lname}</FormErrorMessage>}
            </FormControl>
            <FormControl width={'100%'} mb={'xs'} isInvalid={errors.email && touched.email}>
              <FormLabel htmlFor='email'>Email</FormLabel>
              <Input
                placeholder=''
                id='email'
                bgColor={'white'}
                pr='4.5rem'
                type={'text'}
                name='email'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {errors.email && touched.email && <FormErrorMessage id="feedback">{errors.email}</FormErrorMessage>}
            </FormControl>
            <FormControl width={'100%'} isInvalid={errors.password && touched.password}>
                <FormLabel htmlFor='password'>Password</FormLabel>
                <InputGroup size='md'>
                  <Input
                    placeholder=''
                    id='password'
                    bgColor={'white'}
                    pr='4.5rem'
                    type={show ? 'text' : 'password'}
                    name='password'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <InputRightElement width='4.5rem'>
                    <Button h='1.75rem' size='sm' onClick={handleClick}>
                      {show ? 'Hide' : 'Show'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.password && touched.password && <FormErrorMessage id="feedback">{errors.password}</FormErrorMessage>}
            </FormControl>
          </Box>
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              mt={4}
              colorScheme='green'
              type='submit'
              isLoading={isSubmitting}
              loadingText='Submitting'
            >
              Create New User
            </Button>
            <Button
                width={'min-content'}
                mt={4}
                colorScheme='green'
                variant='link'
                type='button'
                onClick={() => navigate('/login')}
              >
                Back to Login
              </Button>
            </Flex>
        </form>
      </Box>
    </Flex>
  )
}

const SignupWithFormik = withFormik({
  mapPropsToValues: () => ({fname: '', lname: '', email: '', password: ''}),
 
  // Custom sync validation
  validate: values => {
    const checkPass = input => {
      const regxTest = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
      return input.match(regxTest);
    }
    const errors = {};

    if (!values.email) {
      errors.email = 'Email Address is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid Email Address';
    }

    if (!checkPass(values.password)) {
      errors.password = 'Password must be at least 8 characters long and contain one number and one symbol. Allowed symbols: ! @ # $ % ^ & *'
    }

    return errors;
  },

  handleSubmit: (values, {setStatus, setErrors, setSubmitting}) => {
    const createUser = async () => {
      return await createUserWithEmailAndPassword(auth, values.email, values.password);
    }

    createUser()
    .then(userCred => {
      try {
        values.setFBUser(userCred.user.uid, {
          name: `${values.fname} ${values.lname}`,
          email: values.email,
          theme: 'light'
        }).then(() => {
          console.log('Attempting to send email verification message.');
          sendEmailVerification(userCred.user).then(() => {
            console.log('Verification message sent successfully.');
            setStatus({hasError: false, message: <p>Please check your inbox for email verification instructions.</p>, heading: 'Account Successfully Created', action: 'Return to Login'});
          });
        });
      } catch (e) {
        let type = 'Verification Email Failed';
        let message = `${e.message} Please contact support or try again.`;
        setStatus({hasError: true, message: message, heading: type, action: 'Try Again'});
      }
    })
    .catch(e => {
      let emailErrorCode = 'auth/email-already-in-use';
      let emailInUse = e.code === emailErrorCode ? true : false;

      if(emailInUse) {
        setErrors({email: 'Please enter an email address not already in use.'});
        setSubmitting(false);
      } else {
        setStatus({hasError: true, message: 'Creation of user account failed. Please contact support or try again.', heading: 'Create User Error', action: 'Try Again'});
      }
    })
  },

  displayName: 'SignupForm',
})(Signup);

export default SignupWithFormik;