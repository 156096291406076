import { merge } from "lodash";

const LOCAL_KEYS = {
  auth: 'ewaves-auth',
  user: 'ewaves-user',
}

//Read current session from local storage
const getStorage = (key, item = false) => {
  let storage = localStorage.getItem(LOCAL_KEYS[key]);

  if(storage !== "undefined" || typeof storage === "undefined") {
    storage = JSON.parse(storage)
    if(storage && item) {
      let storageItem = storage[item];
      if(storageItem) {
        return storageItem;
      } else {
        console.error('Issue with reading local storage. Item did not exist.')
      }
      
    }
    return storage
  } else {
    console.error('Issue with reading local storage.')
  }
}

const removeStorage = (key) => {
  localStorage.removeItem(LOCAL_KEYS[key]);
}

//Clears and updates the users logged in local storage variable as stringified JSON
const setStorage = (key, data, item = false, options = {merge: false}) => {
  let storage;
  let prevStorage = getStorage(key);

  // console.log('Passed: ', data);
  
  if(item) { //update single item in storage object
    storage = JSON.stringify({...prevStorage, [item]: data});
  } else { //merge session storage entirely
    if(options.merge) {
      let mergedData = merge(prevStorage, data);
      storage = JSON.stringify(mergedData);
    } else {
      storage = JSON.stringify(data);
    }
  }

  //Reset storage item data
  removeStorage(key);

  //Add string to storage item
  localStorage.setItem(LOCAL_KEYS[key], storage);
}

const CACHE_SESSION_KEY = 'ewaves-timestamp';

//Creates a timestamp string in session storage and updates it every 10 mins
const setCacheTimestamp = () => {
  const cacheInMinutes = 10;

  let localTimeStamp;
  let cacheVersion;

  if(window.sessionStorage.getItem(CACHE_SESSION_KEY)) {
    localTimeStamp = window.sessionStorage.getItem(CACHE_SESSION_KEY);
    if ( Math.floor((new Date() - localTimeStamp)/60000) < cacheInMinutes ) {
      cacheVersion = localTimeStamp;
    } else {
      cacheVersion = `${(new Date()).getTime()}`;
      window.sessionStorage.setItem(CACHE_SESSION_KEY, cacheVersion);
    }
  } else {
    window.sessionStorage.setItem(CACHE_SESSION_KEY, `${(new Date()).getTime()}`);
  }
}

const getCacheTimestamp = () => {
  return window.sessionStorage.getItem(CACHE_SESSION_KEY)
}


export { getStorage, setStorage, removeStorage, setCacheTimestamp, getCacheTimestamp }