import { useState, useCallback, useRef, useLayoutEffect, useEffect } from 'react';
import debounce from 'lodash.debounce';

/**
 * Hook that returns height and width dimensions of a DOM component. Updates on resize and orientation change.
 * @returns Height and Width
 * Based on https://github.com/thomasthiebaud/react-use-size/blob/master/src/useComponentSize.ts
 */
export default function useDimensions() {
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  });
  const [ref, setRef] = useState(null);

  const handleResize = useCallback(() => {
    if(ref) {
      setDimensions({
        width: ref.offsetWidth,
        height: ref.offsetHeight,
      });
    }
  }, [ref]);

  useLayoutEffect(() => {
    if(!ref) return;

    handleResize(); // Make sure to fire for once for initial size data

    const debouncedHandleResize = debounce(handleResize, 150);

    const resizeObserver = new ResizeObserver(debouncedHandleResize);
    resizeObserver.observe(ref);

    const mediaQuery = window.matchMedia("(orientation: portrait)");

    function handleOrientationChange(e) {
      resizeObserver.observe(ref); // Trigger a resize observation manually on orientation change
    }

    // const handleOrientationChange = () => {
    //   resizeObserver.observe(ref); // Trigger a resize observation manually on orientation change
    // };
 
    mediaQuery.addEventListener("change", handleOrientationChange);

    return () => {
      resizeObserver.disconnect();
      mediaQuery.removeEventListener("change", handleOrientationChange);
    };
  }, [ref, handleResize]);

  return [
    setRef,
    dimensions,
  ]
}
