//Dependencies
import { useState, useEffect, useContext, useMemo, createContext } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';

//Providers
import { useUser } from '../providers/user';
import { useMarkets } from '../providers/markets';
import { useWaveFinder } from '../providers/wave-finder';

//Helpers
import { load } from '../helpers/bchart';
import { createMarketURLBinaryTokenized } from '../helpers/utils';
import { getMarketBySymbol } from '../helpers/utils';
import { useView } from './view';


const ChartContext = createContext();

function useChart() {
  const context = useContext(ChartContext);
  if (!context) {
    throw new Error(`useChart must be used within a ChartProvider`);
  }

  return context;
}

function ChartProvider(props) {
  const {user} = useUser();
  const {setIsLoadingChartViews} = useView();
  const {markets} = useMarkets();
  const {waves, wfActive, isLoadingWaves} = useWaveFinder();

  const {json} = props;
  const {market: marketParam} = useParams();
  const isSingleView = useMemo(() => marketParam ? true : false, [marketParam]); // Used specifically for the single view that receives a market query param

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamZoom = useMemo(() => parseInt(searchParams.get('z')), [searchParams]); // If z doesn't exist then NaN is returned
  const [zoom, setZoom] = useState(null); // Used to sync zoom between chart components (NOTE: Not persistent navigating from Single -> Charts - Requires refractor)

  const [chartBlob, setChartBlob] = useState(null);
  const [bchart, setBchart] = useState(null); //Necessary for creating bchart which holds default data for market

  const [activeMarket, setActiveMarket] = useState(null);
  const [chartInView, setChartInView] = useState(false);

  // Create a single active Market object that includes json, waves, and bchart data
  useEffect(() => {
    const activeJson = !json ? getMarketBySymbol(markets, marketParam) : json;

    const active = {
      "json": activeJson,
      "waves": waves?.filter(wave => wave?.market === activeJson?.Id),
    }

    setActiveMarket(active);
  }, [json, markets, marketParam, waves]);

  // Loads the binary chart data object into chart provider when it's available (inView)
  useEffect(() => {
    const getBinaryChartAsync = async (marketToken, userPackage, cleanup) => {
      const path = createMarketURLBinaryTokenized(marketToken, userPackage);
      try {
        if(path) {
          const object = await load(path, cleanup);
          setBchart(object);
          setActiveMarket((active) => ({...active, "binary": object}));
        }
      } catch (error) {
        console.error('Error loading binary path', error);
      }
    }

    const cleanup = new AbortController();

    if(chartInView && activeMarket?.json?.Token && user?.package) {
      console.log('In view! I can update bchart now...');
      getBinaryChartAsync(activeMarket.json.Token, user.package, cleanup);
    }

    return () => {
      cleanup.abort();
    }
  }, [activeMarket?.json?.Token, user?.package, chartInView]);
  
  //On chart provider's first render, we'll setup what zoom should be
  useEffect(() => {
    if(isLoadingWaves) {
      setZoom(null);
    }

    if(bchart) {
      const numOfZooms = bchart.zooms.length - 1;

      //Loading single view either from internal link or directly via URL
      if(isSingleView) {
        if(isNaN(parseInt(searchParamZoom))) { 
          setZoom(bchart.defaultZoom);
        } else if(searchParamZoom <= 0) { // Can't be lower than zoom level 0
          setZoom(0);
        } else if(searchParamZoom >= numOfZooms) { // Can't be higher than zoom limit
          setZoom(numOfZooms);
        } else {
          setZoom(parseInt(searchParamZoom));
        }
      } else {
        if(wfActive) {
          setZoom(activeMarket.waves[0].zoom);
        } else {
          setZoom(bchart.defaultZoom);
        }
      }
    }
  }, [wfActive, activeMarket, bchart, isSingleView, searchParamZoom, isLoadingWaves]);

  // Apply zoom updates based on changes to UI
  useEffect(() => {
    if(isSingleView && (zoom || zoom === 0)) {//Loading single view either from internal link or directly via URL
      setSearchParams({z: zoom}, {replace: true}); //sets zoom in url to match zoom in chart when changed via zoom buttons
    }
  }, [isSingleView, setSearchParams, zoom]);

  const value = useMemo(() => ({
    zoom, setZoom,
    chartBlob, setChartBlob,
    chartInView, setChartInView,
    activeMarket,
    bchart,
    isSingleView
  }), [zoom, chartBlob, chartInView, activeMarket, bchart, isSingleView]);

  return <ChartContext.Provider value={value} {...props} />
}

export {ChartProvider, useChart};