//Dependencies
import {
  useEffect,
  useMemo,
  useState
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEqual from "lodash.isequal";

//Chakra
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Divider,
  VStack,
  HStack,
  Heading,
  Checkbox as Tag,
  List,
  ListItem,
  Text,
  Skeleton,
} from "@chakra-ui/react";

//Providers
import { useMarkets } from "../providers/markets";
import { useView } from "../providers/view";
import { useWaveFinder } from "../providers/wave-finder";

//Helpers
// import { setStorage, getStorage } from "../helpers/storage";


const EXPANDED_THRESHOLD = 3;

export default function Markets() {
  const {regions, setMarketIds} = useMarkets();
  const {activeTags, setActiveTags} = useView();
  const {setWaveIds, setPageNumber} = useWaveFinder();
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const defaultExpanded = useMemo(() => {
    if(regions) {
      if(regions.length > EXPANDED_THRESHOLD)  {
        return []; // Empty array means accordion is collapsed
      } else {
        return regions.map((_region, index) => index); // Accordion will expand based on array of indices
      }
    }
  }, [regions]);

  const activeTagExists = (asset, region) => activeTags.find((tag) => tag.asset === asset && tag.region === region) !== undefined;

  const handleClearFilters = () => {
    setActiveTags([]);
  }

  const handleTagClick = (tag) => {
    //Turn tag just clicked into object to use
    const clickedTag = {"region": tag.region, "asset": tag.asset, "waveRange": tag.waveRange, "marketRange": tag.marketRange};

    //Check activeTags to see if clicked tag should be added or removed from activeTags
    const removeTag = activeTags.some(activeTag => isEqual(activeTag, clickedTag));
    let newActiveTags = [];
    
    if(removeTag) {
      //Find the index of the clicked tag in the active tags and then filter it out and set new array to active tags
      const clickedIndex = activeTags.findIndex(activeTag => isEqual(activeTag, clickedTag));
      newActiveTags = activeTags.filter((_activeTag, index) => index !== clickedIndex );
      setActiveTags(newActiveTags);
    } else {
      //Add new tag to active tags
      newActiveTags = [...activeTags, clickedTag];
      setActiveTags(newActiveTags);
    }

    if(newActiveTags.length && pathname !== '/charts') {
      navigate('/charts');
    }
  };

  //Grab array of all activeTag ranges and set that to waveIds
  useEffect(() => {
    setPageNumber(0);
    setWaveIds(activeTags.map(tag => tag.waveRange));
    setMarketIds(activeTags.map(tag => tag.marketRange));
  }, [activeTags, setPageNumber, setWaveIds, setMarketIds]);
  
  return (
    <VStack>
      <VStack alignItems={'stretch'} w={'100%'}>
        <HStack alignItems={'center'} justifyContent={'space-between'}>
          <Heading size={'md'}>Markets</Heading>
        </HStack>
        <Divider />
      </VStack>
      <VStack align='flex-start' position={'relative'} spacing={2} w={'100%'} mb={'md'}>
        <Skeleton isLoaded={regions?.length} width={'100%'}>
          <Accordion defaultIndex={defaultExpanded} allowMultiple>
            { regions && regions.map( ({Name, AssetClasses}, index) => (
                <AccordionItem key={index} marginBottom={'4px'}>
                  <AccordionButton display={'flex'} justifyContent={'space-between'} paddingInline={'xs'} pb={'xs'} backgroundColor={'gray.100'} borderRadius={'sm'} _hover={{bg: 'gray.200'}} role={'heading'}>
                    <Heading as={'h3'} size={'sm'}>{Name}</Heading>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel p={1} pl={2}>
                    <List id={Name}>
                      { AssetClasses && Object.entries(AssetClasses).map(([assetIndex, asset]) => (
                        <ListItem key={assetIndex+asset.name}>
                          <Tag
                            className={activeTagExists(asset.Name, Name) ? `active ${Name}` : undefined}
                            isChecked={activeTagExists(asset.Name, Name) ? true : false}
                            onChange={() => handleTagClick({
                              'regionIndex': index,
                              'region': Name,
                              'assetIndex': assetIndex,
                              'asset': asset.Name,
                              'waveRange': asset.Range,
                              'marketRange': asset.MarketRange
                            })}
                            colorScheme='green'
                          >
                            <HStack gap={1} alignItems={"center"}>
                              <Text>{asset.Name}</Text><Text fontSize={'xs'} mt={'2px'} color={'gray.400'}>{`(${(asset.MarketRange[1]-asset.MarketRange[0])+1})`}</Text>
                            </HStack>
                          </Tag>
                        </ListItem>
                      )) }
                    </List>
                  </AccordionPanel>
                </AccordionItem>
            )) }
          </Accordion>
        </Skeleton>
        {activeTags.length > 0 && (<Button position={'absolute'} top={'100%'} right={'0'} size={'xs'} mt={'xs'} color="primary" rightIcon={<FontAwesomeIcon icon="fa-solid fa-trash-xmark" />} onClick={handleClearFilters}>Clear Filters</Button>)}
      </VStack>
    </VStack>
  );  
}