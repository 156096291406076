//Dependencies
import { forwardRef } from "react";

//Chakra
import { Box } from "@chakra-ui/react";


const Panel = forwardRef(({children, ...props}, ref) => {
  return (
    <Box ref={ref}
      {...props}
      position='absolute'
      left='0' right='0' top='0' bottom='0'
      display={'flex'}
      flexDirection={'column'}
    >
      {children}
    </Box>
  );
})


export default Panel;