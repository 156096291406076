// Dependencies
import { useState, useLayoutEffect, useEffect } from 'react';

// Chakra
import {
  Skeleton,
  useBreakpointValue
} from '@chakra-ui/react';
import { ewavesConfig } from '../config/theme-config';

// Components
import {
  Chart,
  ChartHeader,
  ChartFooter,
} from '../components';

// Providers
import { useView } from '../providers/view';
import { useChart } from '../providers/chart';

// Hooks
import { useDimensions, useWindowSize } from '../hooks';


export default function Full() {
  const {isLoadingChartViews, activeGridView, fullscreen} = useView();
  const {chartInView, bchart} = useChart();
  const [totalHeight, setTotalHeight] = useState(0);
  const [maxChartHeight, setMaxChartHeight] = useState(0);
  const [sRef, size] = useDimensions();
  const [windowWidth, windowHeight] = useWindowSize();

  const headerHeight = useBreakpointValue({ base: ewavesConfig.headerHeightSmall, desktop: ewavesConfig.headerHeightLarge }, { ssr: false });
  const aspectRatio = 16 / 9;

  useLayoutEffect(() => {
    const screenAspect = windowWidth / windowHeight;
    const chartHeight = size?.width / aspectRatio;

    let calcHeight;

    if(screenAspect > 1.85) {
      calcHeight = ((windowHeight - (headerHeight)) - 16) + ewavesConfig.chartFooterMinHeight + ewavesConfig.chartHeaderMinHeight;
    } else {
      calcHeight = chartHeight + ewavesConfig.chartFooterMinHeight + ewavesConfig.chartHeaderMinHeight;
    }
      
    setTotalHeight(calcHeight);
  }, [size?.width, aspectRatio, windowWidth, windowHeight, headerHeight]);

  useLayoutEffect(() => {
    const screenAspect = windowWidth / windowHeight;

    if(screenAspect > 1.85) {
      setMaxChartHeight((windowHeight - (headerHeight)) - 16);
    } else {
      setMaxChartHeight('100%');
    }
  }, [totalHeight, windowWidth, windowHeight, headerHeight]);

  // Changes these styles after things have rendered to reduce conflicts in sizing
  useEffect(() => {
    const chartSkeleton = sRef.current;
    if(sRef?.current && chartInView) {
      chartSkeleton.style.height = 'auto'
      chartSkeleton.style.aspectRatio = 'auto';
    }
  }, [chartInView, sRef]);

  return (
    <>
      <Skeleton ref={sRef} isLoaded={!isLoadingChartViews}
        sx={{
          width: '100%',
          height: totalHeight + "px",
        }}
      >
          {chartInView && totalHeight &&
            <>
              <ChartHeader />
              <Chart maxChartHeight={maxChartHeight} />
              <ChartFooter />
            </>
          }
      </Skeleton>
    </>
  );
}