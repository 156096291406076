//Dependencies
import {
  useMemo
} from "react";
import difference from "lodash.difference";

//Chakra
import {
  Select,
} from "@chakra-ui/react";

//Providers
import { useBuilder } from "../../providers/builder";

//Helpers
import { toTitleCase } from "../../helpers/utils";

//Components
import RangeInput from "./range-input";
import ListInput from "./list-input";


const Attribute = ({contextId, id, name, values, available}) => {
  const {fields, actions} = useBuilder();

  //Available options are only the ones not currently selected
  const availableFields = useMemo(() => {
    if(fields?.length) {
      const attributes = [...fields].filter(field => available.some(val => field.name === val));
      return difference(fields, attributes);
    }
    return;
  }, [fields, available]);

  //Based on name, return the fields format type
  const format = useMemo(() => {
    if(name && fields?.length) {
      const currField = fields.filter(field => field.name === name).pop();
      return currField?.type;
    }
    return;
  }, [name, fields]);

  const handleOnChange = (e) => {
    actions.updateAttribute(contextId, id, e.target.value);
  }
  
  switch (format) {
    case 'list':
      return <ListInput contextId={contextId} id={id} name={name} fields={fields} values={values} />
    case 'range':
      return <RangeInput contextId={contextId} id={id} name={name} fields={fields} values={values} />
    default:
      return (
        <Select variant={'lightPrimary'} size={'sm'} placeholder={`Select Attribute`} onChange={handleOnChange}>
          {availableFields && availableFields.map((field, key) =>
            <option key={field.name+key} value={field.name}>{toTitleCase(field.name)}</option>
          )}
        </Select>
      )
  }
}

export default Attribute;