function decodeFloat16( x ) { // note: taken from https://stackoverflow.com/questions/5678432/decompressing-half-precision-floats-in-javascript)
    "use strict";
    const exponent = (x & 0x7C00) >> 10, fraction = x & 0x03FF;
    return (x >> 15 ? -1 : 1) * (
        exponent ?
        (
            exponent === 0x1F ?
            fraction ? NaN : Infinity :
            Math.pow( 2, exponent - 15 ) * (1 + fraction / 0x400)
        ) :
        6.103515625e-5 * (fraction / 0x400)
    );
};

function pattern_to_symbol( pattern ) {
    switch( pattern ) {
        case 0: return 48; // ascii_code( '0' )
        case 1: return 49; // ascii_code( '1' )
        case 2: return 65; // ascii_code( 'A' )
        case 3: return 97; // ascii_code( 'a' )
        case 4: return 87; // ascii_code( 'W' )
        default: throw new Error( 'unrecognized pattern: ' + pattern );
    }
}

function build_label_objects( labels, labeled_data ) {    
    // define variables
    let label_objects = new Array( labels.length ); // result: label objects
    let last_degree = 0; // degree of last label
    let degree_to_symbol = new Uint8Array( 23 ); // remember last symbol @ a each degree (note: Symbols buffer in ewaves engine has 23-degree limit, so we knoew this is safe)
    let degree_to_start = new Uint16Array( 23 ); // remember the start_index

    // build the stacks
    for( let i = 0; i < labels.length; i++ ) {
        // grab label info
        const degree = labels[i] >> 3;
        const pattern = labels[i] & 0x07;
        if( pattern > 4 ) throw new Error( "unsupported chart pattern: " + pattern );
        const start = degree_to_start[degree];
        const end_t = labeled_data[i * 2];

        // build label object (javascript V8 should create a hidden class here for efficiency)
        label_objects[i] = {
            degree: degree,
            symbols: [],
            start_t: [],
            start_p: [],
            end_t: end_t,
            end_p: labeled_data[i * 2 + 1]
        };

        // unroll symbol stack for non-target waves
        if( end_t <= 0 ) {
            for( let d = last_degree; d > degree; d-- ) {
                label_objects[i].symbols.push( String.fromCharCode( degree_to_symbol[d] + 1 ) );
                if( end_t >= 0 ) { // only care about start for stack waves
                    label_objects[i].start_t.push( labeled_data[degree_to_start[d] * 2] );
                    label_objects[i].start_p.push( labeled_data[degree_to_start[d] * 2 + 1] );
                }
                degree_to_symbol[d] = 0;
                degree_to_start[d] = i;
            }
            degree_to_start[last_degree = degree] = i;
        }

        // push symbol (either by starting a new pattern, or moving to next symbol in pattern)
        const symbol = degree_to_symbol[degree] = 0 == degree_to_symbol[degree] ? pattern_to_symbol( pattern ) : degree_to_symbol[degree] + 1;
        label_objects[i].symbols.push( String.fromCharCode( symbol ) );
        if( end_t >= 0 ) { // only care bout start for stack waves
            label_objects[i].start_t.push( labeled_data[start * 2] );
            label_objects[i].start_p.push( labeled_data[start * 2 + 1] );
        }

        // debug output
        // console.log( "label[" + (i >> 1) + ']: ' + JSON.stringify( labels[i >> 1] ) );
    }
    return label_objects;
}

export async function load( path, cleanup ) { // cleanup: MattM: I added a parameter that allows me to pass an abort / cleanup signal. That way if the charts re-render while a fetch is being executed, that fetch can be aborted instead of having to potentially wait for it to finish.
    // open file
    const response = await fetch( path, { signal: cleanup.signal } );
    const buffer = await response.arrayBuffer();
    const view = new DataView( buffer );
    let ptr = 0;

    // helper function to read utf-8 strings
    const td = new TextDecoder( 'utf-8' );
    function readString( len ) {
        const bytes = new Uint8Array( buffer, ptr, len );
        ptr+= len;
        return td.decode( bytes );
    }

    // read header
    // console.log( "reading header @ 0" );
    const symbol_len = view.getUint8( ptr );
    const title_len = view.getUint8( ptr+=1 );
    const provider_len = view.getUint8( ptr+=1 );
    const zoom_count = view.getUint8( ptr+=1 );
    const label_count = view.getInt16( ptr+=1, true );
    const data_count = view.getInt16( ptr+=2, true );
    const width = view.getFloat64( ptr+=2, true ); // width of nontarget data in days (defined as max distance from LastT)
    const height = view.getFloat64( ptr+=8, true ); // height of nontarget data in log(price) (defined as max absolute distance from lastP)
    const lastT = view.getFloat64( ptr+=8, true ); // last nontarget time in milliseconds from unix epoch
    const lastP = view.getFloat64( ptr+=8, true ); // log( last nontarget price )
    const defaultZoom = view.getUint8( ptr+=8 );
    const elliotticity = view.getUint8( ptr+=1 );
    view.getUint16( ptr+=1, true ); // each the 2 bytes of padding b/c the labled_data section must be 4-byte aligned
    ptr+=2;

    // debug print header data
    // console.log( "header for: " + path );
    // console.log( "  symbol_len: " + symbol_len );
    // console.log( "  title_len: " + title_len );
    // console.log( "  provider_len: " + provider_len );
    // console.log( "  zoom_count: " + zoom_count );
    // console.log( "  label_count: " + label_count );
    // console.log( "  data_count: " + data_count );
    // console.log( "  width: " + width );
    // console.log( "  height: " + height );
    // console.log( "  lastT: " + lastT );
    // console.log( "  lastP: " + lastP );
    // console.log( "  defaultZoom: " + defaultZoom );
    // console.log( "  elliotticity: " + elliotticity );
    
    // read main data
    // console.log( "reading labeled data @ " + ptr );
    const labeled_data = new Float32Array( buffer, ptr, label_count * 2 );
    ptr+= label_count * 8;

    // console.log( "reading unlabeled data @ " + ptr );
    const raw_unlabeled_data = new Uint16Array( buffer, ptr, data_count * 2 );
    ptr+= data_count * 4;

    // console.log( "reading zooms @ " + ptr );
    const zoom_pointers = new Uint16Array( buffer, ptr, zoom_count );
    ptr+=zoom_count * 2;

    // console.log( "reading labels @ " + ptr );
    const labels = new Uint8Array( buffer, ptr, label_count );
    ptr+=label_count;

    // console.log( "reading strings @ " + ptr );
    const symbol = readString( symbol_len );
    const title = readString( title_len );
    const provider = readString( provider_len );

    // scale labled data
    const scaleT = width / 65504.0;
    const scaleP = height / 65504.0;
    for( let i = 0; i < labeled_data.length; i++ ) labeled_data[i] = labeled_data[i] * ((i & 1) ? scaleP : scaleT );

    // decompress unlabeled data
    let unlabeled_data = new Float32Array( data_count * 2 );
    for( let i = 0; i < raw_unlabeled_data.length; i++ ) unlabeled_data[i] = decodeFloat16( raw_unlabeled_data[i] ) * ((i & 1) ? scaleP : scaleT );

    // merge labeled & unlabeled data
    let data = new Float32Array( 2 * (label_count + data_count) );
    for( let i = 0, j = 0, k = 0; i < labeled_data.length || j < unlabeled_data.length; k+=2 ) {
        if( i < labeled_data.length && (j >= unlabeled_data.length || labeled_data[i] < unlabeled_data[j]) ) {
            data[k] = labeled_data[i];
            data[k + 1] = labeled_data[i + 1];
            i+=2;
        } else {
            data[k] = unlabeled_data[j];
            data[k + 1] = unlabeled_data[j + 1];
            j+=2;
        }
    }

    // build label objects
    const label_objects = build_label_objects( labels, labeled_data );

    // build zoom objects
    let zooms = new Array( zoom_pointers.length );
    for( let i = 0, label_index = 0; i < zoom_pointers.length; i++ ) {
        // determine data zoom
        const data_index = zoom_pointers[i] * 2;
        const zoom_t = data[data_index];

        // determine label zoom
        while( label_index + 1 < label_objects.length && 
               label_objects[label_index + 1].end_t <= zoom_t )
               label_index+=1;

        // set the zoom
        zooms[i] = { data_index: data_index, label_index: label_index };
    }

    // return bchart json representation
    return {
        symbol: symbol,
        title: title,
        provider: provider,
        lastT: lastT,
        lastP: lastP,
        elliotticity: Math.round( elliotticity / 2.55 ),
        defaultZoom: defaultZoom,
        zooms: zooms,
        labels: label_objects,
        data: data,
    };
}

function get_date_string( bchart, time, copyright = false, tooltip = false ) {
    // convert time to javascript date
    const msFromUnixEpoch = (time * 8.64e+7) + bchart.lastT;
    const date = new Date( msFromUnixEpoch );
    const year = date.getFullYear();
    const day = date.getDate();
    const monthName = date.toLocaleString( 'en-US', {month: 'short'} );

    // format date into a string. note that we have safeguards s.t. we make sure whatever aspects of the date we show, we know are going to be accurate given our 16-bit date precision
    return copyright ? monthName + " " + day + ", " + year  :
           tooltip   ? (Math.abs( time ) > 365 * 42) ? year : (Math.abs( time ) > 365 * 1.4) ? monthName + "-" + year : day + "-" + monthName + "-" + year :
                       (Math.abs( time ) > 365 * 42) ? year : (Math.abs( time ) > 365 * 1.4) ? monthName + year : day + monthName + year;
}

function get_price_string( bchart, x ) {
    // convert to arithmetic space
    x = Math.exp( bchart.lastP + x );

    // use 'K' units for values larger than 1K
    let units = '';
    if( x > 1000 ) { x/=1000; units = 'K'; }

    // if it's >= 1, then convert to string w/ 2 decimal digits, otherwise use 8 (note that max # of characters is 9, so .######## maxes us out!)
    x = x.toFixed( x >= 1 ? 2 : 8 );

    // removing leading zeros
    return (Number( x ) >= 1 || "0" == x ? x : x.substring( 1 )) + units;
}

function get_databox( data ) {
    // first, find max & min prices across non-target space
    let maxPrice = data[1];
    let minPrice = data[1];
    for( let i = 2; i < data.length && data[i] <= 0; i+=2 ) {
        maxPrice = Math.max( maxPrice, data[i + 1] );
        minPrice = Math.min( minPrice, data[i + 1] );
    }

    // done
    return {
        left: data[0],
        right: 0,
        top: maxPrice,
        bottom: minPrice,
        width: -data[0],
        height: maxPrice - minPrice,
    };
}

function get_relevant_degree( labels ) {
    let min1 = Number.POSITIVE_INFINITY, min2 = Number.POSITIVE_INFINITY;
    for( let i = 0; i < labels.length && labels[i].end_t <= 0; i++ ) {
        // skip super (degree == 0), and degree > min2
        const degree = labels[i].degree;
        if( degree == 0 || degree > min2 ) continue;

        // update min2 & min1
        min2 = Math.max( min1, degree );
        min1 = Math.min( min1, degree );
    }
    return min2 < Number.POSITIVE_INFINITY ? min2 : min1 < Number.POSITIVE_INFINITY ? min1 : 0;
}

function degree_to_color( degree ) {
    switch( degree % 5 ) {
        case 1: return 'red';
        case 2: return 'royalblue';
        case 3: return 'green';
        case 4: return 'purple';
        default: return '#808080';
    }
}

function degree_to_opacity( degree ) { // for target waves, these are adjusted manually for visual appearance
    switch( degree % 5 ) {
        case 1: return 0.9;
        case 2: return 1.25;
        case 3: return 1;
        case 4: return 1;
        default: return 1.5;
    }
}

// this is used to create the 'arrowhead' for target waves
function vector_rescale_and_rotate30( x, y, scale ) {
    scale/= Math.sqrt( x*x + y*y );
    const cos = scale * .866025404;
    const sin = scale * .5;
    return [cos * x - sin * y, sin * x + cos * y, // rotated +30 degrees
            cos * x + sin * y, cos * y - sin * x]; // rotated -30 degrees
}

export function to_svg( bchart, zoom_index ) {
    // define render constants
    const WIDTH = 1920, // 16:9 resolution charts
          HEIGHT = 1080,
          FONT_SHRINK = .7, // ratio of size of each smaller degree
          CONTENT_TOP = 40, // content = the actual chart content w/ data & labels (i.e. chart minus the borders)
          CONTENT_LEFT = 10,
          CONTENT_RIGHT = WIDTH - 130,
          CONTENT_BOTTOM = HEIGHT - 40,
          CONTENT_WIDTH = CONTENT_RIGHT - CONTENT_LEFT,
          CONTENT_HEIGHT = CONTENT_BOTTOM - CONTENT_TOP;

    // sanity check zoom #
    if( zoom_index < 0 || zoom_index >= bchart.zooms.length )
        throw new Error( "requested zoom_index out-of-bounds: " + zoom_index + ", expected 0 to " + (bchart.zooms.length - 1) );
    
    // slice labels & data for this zoom
    const zoom = bchart.zooms[zoom_index];
    const labels = bchart.labels.slice( zoom.label_index );
    const data = bchart.data.slice( zoom.data_index );

    // calculate relevant_degree & databox
    const relevant_degree = get_relevant_degree( labels );
    const databox = get_databox( data );
    // console.log( "relevant_degree: " + relevant_degree );
    // console.log( "databox: " + JSON.stringify( databox ) );

    // define data transformation constants
    const fontSize = 30, // size of largest font
          fontWidthPercent = fontSize * .001, //fontSize of 30 maps to 3% of chart width
          fontHeightPercent = 1.2 * fontWidthPercent, // smallest ratio we can get away with
          padHorizontal = databox.width * fontWidthPercent * .5,
          padVertical = databox.height * fontHeightPercent * (FONT_SHRINK / (1 - FONT_SHRINK)), // geometric series of 'FONT_SHRINK's to give maximum verical space required for a stack of symbols
          padLeft = databox.left - padHorizontal,
          padRight = databox.right + padHorizontal,
          padTop = databox.top + padVertical,
          padBottom = databox.bottom - padVertical;

    // define transformation functions
    const scale_x = CONTENT_WIDTH/(padRight-padLeft);
    const shift_x = CONTENT_LEFT - padLeft * scale_x;
    function data_to_screen_x( x ) { return x * scale_x + shift_x; }

    const scale_y = CONTENT_HEIGHT/(padBottom-padTop);
    const shift_y = CONTENT_HEIGHT + CONTENT_TOP - padBottom * scale_y;
    function data_to_screen_y( y ) { return y * scale_y + shift_y; }

    // SVG via template literal
    // note: y-corrdinates for title-region tspans is only required b/c iOS doesn't support the title-region being dominant-baseline='middle'. Note that leftside-text and rightside-text was y=22 before we removed the dominant-baseline='middle'
    return `
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version='1.1' width='100%' viewBox='0 0 ${WIDTH} ${HEIGHT}'>
            <rect id='background' width='100%' height='100%' fill='#F3F3F3'/>
            <rect id='content-background' fill='white' stroke='lightgray' stroke-width='1.5px' x='${CONTENT_LEFT}' y='${CONTENT_TOP}' width='${CONTENT_WIDTH}' height='${CONTENT_HEIGHT}'/>
            <g id='title-region' fill='none' stroke='none' width='${CONTENT_WIDTH + 5}' height='${CONTENT_TOP}' font-family='monospace' font-style='normal' font-weight='normal'>
                <text id='leftside-text' fill='black' x='${CONTENT_LEFT}'>
                    <tspan id='symbol' y='32' font-size='37' font-weight='bold'>${bchart.symbol} </tspan>
                    <tspan id='title' y='28' font-size='25'>${bchart.title} </tspan>
                    <tspan id='provider' y='27' font-size='20' fill='#808080'>(${bchart.provider})</tspan>
                </text>
                <text id='rightside-text' fill='black' text-anchor='end' x='${CONTENT_RIGHT}' y='28'>
                    <tspan id='copyright' font-size='22' fill='#707070'> © ${get_date_string( bchart, 0, true )} </tspan>
                    <tspan id='website' font-size='22' fill='#0000EE'>ewaves.com</tspan>            
                </text>
            </g>
            <defs>
                <clipPath id='content_clip_path'>
                    <rect x='${CONTENT_LEFT}' y='${CONTENT_TOP}' width='${CONTENT_WIDTH}' height='${CONTENT_HEIGHT}'/>
                </clipPath>
            </defs>
            <g id='date-axis'>
                ${(function f() {
                    let items = [];
                    for( let i = 0; i <= 5; i++ ) {
                        const x = data_to_screen_x( databox.left + (i * databox.width) / 5 );
                        const anchor = i > 0 ? `text-anchor='middle' x='${x}'` : "";
                        const dx = 0 == i ? "dx='2'" : "";
                        const date = databox.left + (i * databox.width) / 5;
                        const date_string = get_date_string( bchart, date );
                        items.push(
                           `<line fill='none' stroke='darkgray' stroke-width='1.5px' x1='${x}' x2='${x}' y1='${CONTENT_BOTTOM}' y2='${HEIGHT - (HEIGHT - CONTENT_BOTTOM) / 2}'/>
                            <line fill='none' stroke='#F0F0F0' stroke-width='1.5px' x1='${x}' x2='${x}' y1='${CONTENT_TOP}' y2='${CONTENT_BOTTOM}'/>
                            <text font-size='28' font-family='monospace' font-weight='normal' font-style='normal' dominant-baseline='hanging' ${anchor} y='${CONTENT_BOTTOM}' ${dx} dy='10'>${date_string}</text>` );
                    }
                    return items.join( "" ); 
                })()}
            </g>
            <g id='price-axis'>
                ${(function f() {
                    let items = [];
                    for( let i = 0; i <= 5; i++ ) {
                        const y = data_to_screen_y( databox.top - (i * databox.height) / 5 );
                        const price = databox.bottom + (5 - i) * databox.height / 5;
                        const arithmeic_price = get_price_string( bchart, price );
                        items.push(
                            `<line fill='none' stroke='darkgray' stroke-width='1.5px' x1='${CONTENT_RIGHT}' x2='${CONTENT_RIGHT + 10}' y1='${y}' y2='${y}'/>
                             <line fill='none' stroke='#F0F0F0' stroke-width='1.5px' x1='${CONTENT_LEFT}' x2='${CONTENT_RIGHT}' y1='${y}' y2='${y}'/>
                             <text font-size='28' font-family='monospace' font-weight='normal' font-style='normal' dominant-baseline='middle' x='${CONTENT_RIGHT}' y='${y}' dx='10'>${arithmeic_price}</text>`);
                    }
                    return items.join( '' ); 
                })()}
            </g>
            <g id='waves' visibility='hidden' clip-path='url(#content_clip_path)' font-family='monospace' font-weight='normal' font-style='normal' text-anchor='left' dominant-baseline='middle'>
                ${(function f() {
                    // transform key points
                    const top = data_to_screen_y( padTop );
                    const bottom = data_to_screen_y( padBottom );
                    const targetRight = data_to_screen_x( padRight );
                    const completeRight = data_to_screen_x( 0 );

                    // generate stack waves
                    let items = [];
                    for( let i = labels.length - 1; i >= 0; i-- ) {
                        // halt when we hit a non-stack wave
                        const end_t = labels[i].end_t;
                        if( end_t < 0 ) break;
                        
                        // gather info for this wave
                        const end_p = labels[i].end_p;
                        const right = end_t > 0 ? targetRight : completeRight;
                        const tooltip = get_date_string( bchart, end_t, false, true ) + " " + get_price_string( bchart, end_p );
                        const end_x = data_to_screen_x( 0 );
                        const end_y = data_to_screen_y( Math.min( Math.max( end_p, databox.bottom ), databox.top ) );

                        // draw each symbol of wave's stack (from largest degree to smallest)
                        for( let j = labels[i].symbols.length - 1; j >= 0; j-- ) {
                            // gather info for this subwave
                            const symbol = labels[i].symbols[j];
                            const degree = labels[i].degree + (labels[i].symbols.length - j - 1);
                            const color = degree_to_color( degree );
                            //console.log( "symbol: " + symbol );
                            //console.log( "degree: " + degree );
                            const opacity = degree_to_opacity( degree );
                            const start_t = labels[i].start_t[j];
                            const start_p = labels[i].start_p[j];
                            const start_x = data_to_screen_x( start_t );
                            const start_y = data_to_screen_y( start_p );
                           // console.log( "start_t: " +  start_t );
                            
                            // target arrow & symbol (only for target waves)
                            let target = "";
                            if( end_t > 0 ) {
                                // determine font info
                                const color = degree_to_color( degree );
                                const adjusted_degree = degree - relevant_degree - 1;
                                const scale_factor = Math.pow( FONT_SHRINK, adjusted_degree );
                                const scaled_font_size = fontSize * scale_factor;    

                                // draw arrow & symbol
                                const [dx1, dy1, dx2, dy2] = vector_rescale_and_rotate30( end_x - start_x, end_y - start_y, Math.min( scale_factor * 10, 20 ) );
                                target =
                                `
                                    <g fill='none' stroke='${color}' stroke-width='2px' stroke-opacity='${.5 * opacity}'>
                                        <line stroke-opacity='${.25 * opacity}' stroke-dasharray='5,5' x1='${start_x}' y1='${start_y}' x2='${end_x}' y2='${end_y}'/>
                                        <line x1='${end_x - dx1}' y1='${end_y - dy1}' x2='${end_x}' y2='${end_y}'/>
                                        <line x1='${end_x - dx2}' y1='${end_y - dy2}' x2='${end_x}' y2='${end_y}'/>
                                    </g>
                                    <text font-size='${scaled_font_size}px' x='${end_x}' y='${end_y}' fill='${color}' fill-opacity='${.25 * opacity}'>
                                        ${symbol}
                                        <title>${tooltip}</title>
                                    </text>
                                `;
                            }

                            // visibility wrapper. wave0 = degree0 (super), wave1 = degree1, etc. note: for debugging: visibility='${i == labels.length - 1 ? "visible" : "hidden"}'
                            items.push(
                                `<g id='wave${degree}'>
                                    <rect fill='${color}' fill-opacity='${.063 * opacity}' x='${start_x}' y='${top}' width='${right - start_x}' height='${bottom - top}'/>
                                    ${target}
                                </g>`
                            );
                        }
                    }
                    return items.join( '' );
                })()}
            </g>
            ${(function zoom_line() {
                if( zoom_index >= bchart.zooms.length - 1 ) return '';
                const data_index = bchart.zooms[zoom_index + 1].data_index; // must use data_index, b/c label_index might not exist if labels are empty/null
                const zoom_t = bchart.data[data_index];
                const zoom_x = data_to_screen_x( zoom_t );
                return `<line id='zoom-line' fill='none' stroke='#38a169B0' stroke-width='2px' stroke-dasharray='8,8' x1='${zoom_x}' y1='${CONTENT_TOP}' x2='${zoom_x}' y2='${CONTENT_BOTTOM}'/>`;
            })()}
            <polyline id='data' fill='none' stroke='black' stroke-width='1.5px' points='
                ${(function f() {
                    let points = [];
                    for( let i = 0; i < data.length && data[i] <= 0; i+=2 ) {
                        points.push( data_to_screen_x( data[i] ) );
                        points.push( data_to_screen_y( data[i + 1] ) );
                    }
                    return points.join( ' ' );
                })()}
            '/>
            <g id='labels' clip-path='url(#content_clip_path)' font-family='monospace' font-weight='normal' font-style='normal' text-anchor='middle'>
                ${(function f() {
                    // transform & stringify nontarget labels
                    let up = labels.length > 1 && labels[0].end_p > labels[1].end_p;
                    let label_strings = [];
                    for( let i = 0; i < labels.length && labels[i].end_t <= 0; i++, up = !up ) {
                        // skip null labels
                        if( 0 == labels[i].symbols.length ) continue;

                        // extract label info
                        const symbols = labels[i].symbols;
                        const end_t = labels[i].end_t;
                        const end_p = labels[i].end_p;
                        const x = data_to_screen_x( end_t );
                        const y = data_to_screen_y( end_p );
                        const tooltip = get_date_string( bchart, end_t, false, true ) + " " + get_price_string( bchart, end_p );
                        
                        // draw the symbol stack
                        let degree = labels[i].degree + symbols.length;
                        let stack_yoffset = 0;
                        for( const symbol of symbols ) {
                            const color = degree_to_color( --degree );
                            const adjusted_degree = degree - relevant_degree - 1;
                            const scale_factor = Math.pow( FONT_SHRINK, adjusted_degree );
                            const scaled_font_size = fontSize * scale_factor;

                            // browser optimization: if font is too small, don't create the text element
                            if( scaled_font_size < 7 ) continue;

                            // push that sucker
                            label_strings.push(
                                `<text font-size='${scaled_font_size}' x='${x}' y='${y}' dy='${stack_yoffset}' fill='${color}' dominant-baseline='${up ? "auto" : "hanging"}'>
                                    ${symbol}
                                    <title>${tooltip}</title>
                                </text>`
                            );

                            // shift to next symbol in the stack
                            stack_yoffset+= (fontSize * scale_factor * .825) * (up ? -1 : 1); // note: the .825 constant is there to reduce vertical padding
                        }
                    }
                    return label_strings.join( '\n' );
                })()}
            </g>
        </svg>
    `;
}
