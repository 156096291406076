//Dependencies
import { useState } from 'react';
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import {
  withFormik
} from 'formik';
import { useNavigate } from 'react-router-dom';

//Chakra
import {
  Box,
  Text,
  Heading,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Link,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useColorMode
} from "@chakra-ui/react";

//Providers
import { useAuthentication } from '../providers/authentication';

//Components
import { Logo } from '../components';

//Helpers
import { auth } from '../helpers/firebase';


const Login = props => {
  const {setIsLoading} = useAuthentication();
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const {colorMode} = useColorMode();

  const handleClick = () => setShow(!show);
  const handleReset = () => {
    navigate('/reset');
  }

  values.navigate = navigate;
  values.setIsLoading = setIsLoading;

  return (
    <Flex justifyContent={'center'} alignItems={'center'} height={'100vh'}>
      <Box p={{base: '5', sm: '10', lg: '20'}} bgColor={colorMode === 'light' ? 'gray.300' : 'gray.600'} width={{base: '100%', sm: '500px', md: '600px'}}>
        <Heading size={'xl'} mb={'md'}>Welcome to <Logo className='logo__image' display='inline-flex' h='25px' /></Heading>
        <Box mb={'md'}>
          <Heading size={'md'} mb='xs'>User Login</Heading>
          <Text>
            Please log into your EWAVES Live user account
          </Text>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box mb={'md'}>
            <FormControl width={'100%'} mb={'xs'} isInvalid={errors.email && touched.email}>
              <FormLabel htmlFor='email'>Email</FormLabel>
              <Input
                placeholder=''
                id='email'
                type={'text'}
                name='email'
                bgColor={'field'}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                width={'100%'}
              />
              {errors.email && touched.email && <FormErrorMessage id="feedback">{errors.email}</FormErrorMessage>}
            </FormControl>
            <FormControl width={'100%'} isInvalid={errors.password && touched.password}>
                <FormLabel htmlFor='password'>Password</FormLabel>
                <InputGroup size='md'>
                  <Input
                    placeholder=''
                    id='password'
                    type={show ? 'text' : 'password'}
                    name='password'
                    bgColor={'field'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    width={'100%'}
                  />
                  <InputRightElement width='4.5rem'>
                    <Button h='1.75rem' size='sm' onClick={handleClick}>
                      {show ? 'Hide' : 'Show'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.password && touched.password && <FormErrorMessage id="feedback">{errors.password}</FormErrorMessage>}
            </FormControl>
            {/* /<Text fontSize={'sm'} mt={'xs'}>Forgot your password? <Button fontSize={'sm'} colorScheme='black' variant='link' onClick={handleReset}>Click here to reset.</Button></Text> */}
            <Text fontSize={'sm'} mt={'xs'}>Forgot your password? <Link fontSize={'sm'} colorScheme='black' variant='link' href='https://profile.elliottwave.com/password-reset?action=request' isExternal style={{'textDecoration': 'underline'}}>Click here to reset.</Link></Text>
          </Box>
          <Flex direction={'column'} justifyContent={'space-between'}>
            <Button
              width={'min-content'}
              mt={4}
              colorScheme='green'
              type='submit'
              isLoading={isSubmitting}
              loadingText='Submitting'
            >
              Log In
            </Button>
            <Link fontSize={'medium'} mt={'2'} style={{'textDecoration': 'underline'}} href="https://ewaves.com">Not a Subscriber? Learn about EWAVES here.</Link>
            {/* <Button
              width={'min-content'}
              mt={4}
              colorScheme='green'
              variant='link'
              type='button'
              onClick={() => navigate('/signup')}
            >
              Signup Here
            </Button> */}
          </Flex>
        </form>
      </Box>
    </Flex>
  )
}

const LoginWithFormik = withFormik({
  mapPropsToValues: (props) => ({ email: '', password: '', errors: props.errors }),

  // Custom sync validation
  validate: (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Email Address is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid Email Address';
    }

    if (!values.password) {
      errors.password = 'Password is required';
    }

    return errors;
  },

  handleSubmit: (values, {setErrors, setSubmitting}) => {
    setSubmitting(true);
    signInWithEmailAndPassword(auth, values.email, values.password)
      .then(userCred => {
        const user = userCred.user;
        
        //Don't allow a sign-in if the user hasn't verified their email
        if(!user?.emailVerified) {
          setErrors({email: 'Users must verify their email address before continuing.'});
          signOut(auth);
          setSubmitting(false);
        } else {
          values.navigate('/', {replace: true});
        }
      })
      .catch((error) => {
        if(error.code === 'auth/user-not-found') {
          setErrors({email: `User Email Address was not found.`});
        }
        if(error.code === 'auth/wrong-password') {
          setErrors({password: `Password was incorrect.`});
        }
        setSubmitting(false);
      });
  },

  displayName: 'LoginForm',
})(Login);

export default LoginWithFormik;