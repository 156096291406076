//Chakra
import {
  chakra,
  useColorModeValue
} from "@chakra-ui/react";

//Assets
import { ReactComponent as SVGLogo } from '../assets/ewaves-live-logo.svg';


export default function Logo(props) {
  const LogoWChakra = chakra(SVGLogo);

  let logoStyles = {
    '.logo__square-text': {
      fill: '#fff',
    },
    '.logo__square': {
      fill: '#39b54a',
    },
    '.logo__square__outline': {
      fill: '#83ddad',
    },
    '.logo__text': {
      fill: useColorModeValue( 'blackAlpha.900', 'whiteAlpha.900'),
    }
  }

  return (
    <LogoWChakra className='logo__image' {...props} sx={logoStyles} />
  )
}
